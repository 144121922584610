import { useAuth0 } from '@auth0/auth0-react';
import { AppBar, Divider, Grid, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import logo from '../../src/logoHorizontal.png';
import { NavigationDrawerContainer } from './NavigationDrawerContainer';
import { AuthenticatedComponent, UserAvatar, LoadingIndicator, PermissionToggler } from './CoreLib/library';
import { useLazyGetUserMeQuery, useLazyGetUserPermissionsQuery } from '../store/generated/generatedApi';
import { LicensePage } from './LicensePage';

export interface IShellProps {
    isProtected?: boolean;
}

const Shell: FC<IShellProps> = (props) => {
    const { isProtected } = props;
    const { user, isAuthenticated, loginWithRedirect, logout, isLoading: isAuthLoading } = useAuth0();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const profileMenuOpen = Boolean(anchorEl);
    const [getUserData, { data: userFromApi }] = useLazyGetUserMeQuery();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            getUserData();
        }
    }, [getUserData, isAuthenticated]);

    useEffect(() => {
        const path = localStorage.getItem('redirectUrl');
        if (!!path && path !== '/') {
            navigate(path);
            localStorage.removeItem('redirectUrl');
        }
    }, [navigate]);

    if (isProtected && !isAuthenticated) {
        localStorage.setItem('redirectUrl', window.location.pathname + window.location.search);
        loginWithRedirect();
        return <LoadingIndicator />;
    }

    const handleLogout = () => {
        setAnchorEl(null);
        logout({
            logoutParams: {
                returnTo: window.location.origin,
            },
        });
    };

    const handleOrgChange = () => {
        loginWithRedirect();
    };

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <NavigationDrawerContainer>
                <AppBar position='sticky' color='transparent' elevation={0} sx={{ height: '72px', background: '#FFFFFF' }}>
                    <Toolbar variant='dense'>
                        <Grid item sx={{ paddingY: 0.5, marginX: 0, paddingX: 0 }}>
                            <a href='/'>
                                <img src={logo} alt='Yoo Data Direct Logo' height={'64px'} />
                            </a>
                        </Grid>
                        {isAuthenticated && (
                            <Grid container direction='row' justifyContent='end'>
                                <div style={{ cursor: 'pointer' }} onClick={handleProfileMenuOpen}>
                                    <UserAvatar name={user?.name} />
                                </div>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={profileMenuOpen}
                                    onClose={handleProfileMenuClose}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    PaperProps={{
                                        sx: {
                                            mt: 1.5,
                                            border: 'solid 1px #AAAAAA',
                                            borderRadius: '10px',
                                            padding: '8px',
                                        },
                                    }}>
                                    <Grid container direction='column' alignItems='center' sx={{ width: '240px' }}>
                                        <UserAvatar name={user?.name} />
                                        <Typography sx={{ fontSize: '18px', marginTop: '12px' }}>{user?.name}</Typography>
                                        <Typography sx={{ fontSize: '14px', marginBottom: '12px' }}>{user?.email}</Typography>
                                        <Typography>{userFromApi?.currentOrganizationName}</Typography>
                                    </Grid>
                                    <Divider sx={{ marginBottom: '12px' }} />
                                    {userFromApi && userFromApi?.isInMultipleOrganizations && (
                                        <MenuItem onClick={handleOrgChange} sx={{ fontSize: '14px' }}>
                                            Switch Organization
                                        </MenuItem>
                                    )}
                                    <MenuItem onClick={handleLogout} sx={{ fontSize: '14px' }}>
                                        Sign Out
                                    </MenuItem>
                                </Menu>
                            </Grid>
                        )}
                    </Toolbar>
                </AppBar>
                <div style={{ minHeight: 'calc(100vh - 72px)' }}>
                    {isAuthLoading || !isAuthenticated || userFromApi === undefined ? (
                        <LoadingIndicator />
                    ) : (
                        <>{userFromApi?.isLicenseAccepted ? <Outlet /> : <LicensePage />}</>
                    )}
                </div>
            </NavigationDrawerContainer>
            <AuthenticatedComponent
                requiredPermissions={['read:permissionToggle']}
                children={<PermissionToggler getPermissionsQuery={useLazyGetUserPermissionsQuery} />}
            />
        </>
    );
};

export default Shell;
