import {FC} from 'react';
import { MeasurementRangeDto, MeasurementWithRangesDto } from '../../dtos';
import { Box, Button, Divider, Typography } from '@mui/material';
import { MeasurementRangeRow } from './MeasurementRangeRow';
import { AddCircle } from '@mui/icons-material';
import { MeasurementRangeValidationFields } from './useMeasurementRangeFormManager';

export interface IMeasurementRangeSectionProps {
    measurement: MeasurementWithRangesDto;
    onChange: (rangeIdx: number) => (updatedRange: MeasurementRangeDto) => void;
    onDelete: (rangeIdx: number) => (updatedRange: MeasurementRangeDto) => void;
    onAdd: () => void;
    errorMessages?: Map<number, Map<MeasurementRangeValidationFields, string>>;
};

export const MeasurementRangeSection: FC<IMeasurementRangeSectionProps> = ({
    measurement,
    onChange,
    onDelete,
    onAdd,
    errorMessages
}) => {

    return (
        <Box display='flex' gap={1} alignItems='center' sx={{ border: '1px solid #aaaaaa', borderRadius: '8px', p: 1 }}>
            <Box width={'128px'}>
                <Typography overflow='hidden' textOverflow='ellipsis'>
                    {measurement.name}
                </Typography>
            </Box>
            <Box display='flex' flexDirection='column' gap={1} width='calc(100% - 128px)'>
                {measurement.ranges.map((range, index) => (
                    <>
                        {index > 0 && <Divider key={`range-${index}-divider`} />}
                        <MeasurementRangeRow
                            key={`range-${index}`}
                            range={range}
                            onChange={onChange(index)}
                            onDelete={onDelete(index)}
                            errorMessages={errorMessages?.get(index)}
                        />
                    </>
                ))}
                <Button fullWidth color='primary' variant='contained' startIcon={<AddCircle />} onClick={onAdd}>
                    New Rule
                </Button>
            </Box>
        </Box>
    );
};