import { Autocomplete, FormControl, FormHelperText, FormLabel, TextField } from '@mui/material';
import { FC } from 'react';
import { useGetMeasurementQuery } from '../../store/generated/generatedApi';
import { PanelMeasurementDto } from '../../dtos';

export interface IMeasurementSelectProps {
    initialMeasurements: PanelMeasurementDto[];
    onChange: (panelMeasurements: PanelMeasurementDto[]) => void;
    errorText?: string;
    label?: string;
    required?: boolean;
    fullWidth?: boolean;
}

export const MeasurementSelect: FC<IMeasurementSelectProps> = ({
    initialMeasurements,
    onChange,
    errorText = '',
    label = 'Measurement',
    required,
    fullWidth,
}) => {
    const { data: knownMeasurements, isLoading: isLoadingMeasurement } = useGetMeasurementQuery();

    const knownMeasurementsArray = knownMeasurements?.pageResults || [];
    let panelMeasurements: PanelMeasurementDto[] = [...initialMeasurements];

    knownMeasurementsArray
        .flatMap((m) => m.measurements)
        .forEach((measurement) => {
            if (!initialMeasurements.find((initial) => initial.id === measurement.id)) {
                panelMeasurements.push({
                    name: measurement.friendlyName,
                    id: measurement.id,
                });
            }
        });

    return (
        <FormControl required={required} fullWidth={fullWidth} error={!!errorText}>
            <FormLabel>{label}</FormLabel>
            <Autocomplete
                loading={isLoadingMeasurement}
                multiple
                value={initialMeasurements}
                selectOnFocus
                onChange={(event, value) => onChange(value || [])}
                clearOnBlur
                options={panelMeasurements}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} error={!!errorText} />}
                filterSelectedOptions
            />
            <FormHelperText>{errorText}</FormHelperText>
        </FormControl>
    );
};
