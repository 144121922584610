import { Autocomplete, FormControl, FormHelperText, FormLabel, IconButton, TextField } from '@mui/material';
import { FC, useState } from 'react';
import { useGetMeasurementAliasesQuery } from '../../store/generated/generatedApi';
import AddCircleIcon from '@mui/icons-material/AddCircle';

export interface IAliasesSelectProps {
    value: string[];
    onChange: (udpatedValue: string[]) => void;
    errorText?: string;
    label?: string;
    required?: boolean;
    fullWidth?: boolean;
    allUsedAliases: string[];
}

export const AliasesSelect: FC<IAliasesSelectProps> = ({ value, onChange, errorText = '', label = 'Aliases', required, fullWidth, allUsedAliases }) => {
    const { data: knownAliases, isLoading: isLoadingAliases } = useGetMeasurementAliasesQuery();
    const [aliasesName, setAliasesName] = useState('');

    return (
        <FormControl required={required} fullWidth={fullWidth} error={!!errorText}>
            <FormLabel>{label}</FormLabel>
            <Autocomplete
                loading={isLoadingAliases}
                multiple
                selectOnFocus
                clearOnBlur
                freeSolo
                value={value}
                options={knownAliases?.pageResults || []}
                onChange={(_, newValue) => {
                    onChange(newValue as string[]);
                }}
                onInputChange={(event, newInputValue) => {
                    setAliasesName(newInputValue);
                }}
                renderInput={(params) => <TextField {...params} error={!!errorText} />}
                filterOptions={(options, params) => {
                    var searchText = params.inputValue.toLowerCase();
                    // filter options based on search text
                    let filtered = options.filter((option) => option.toLowerCase().includes(searchText));
                    if (aliasesName !== '' && !knownAliases?.pageResults.includes(aliasesName) && !allUsedAliases.includes(aliasesName)) {
                        filtered.push(aliasesName);
                    }

                    // filter out options already selected in this measurement or any other measurement
                    filtered = filtered.filter((option) => !allUsedAliases.includes(option as string));

                    return filtered;
                }}
                renderOption={(props, option) => (
                    <li {...props}>
                        {typeof option === 'string' && !knownAliases?.pageResults.includes(option) ? (
                            <>
                                <IconButton edge='start' size='small' color='primary'>
                                    <AddCircleIcon />
                                </IconButton>
                                {`Add: ${option}`}
                            </>
                        ) : (
                            option
                        )}
                    </li>
                )}
            />
            <FormHelperText>{errorText}</FormHelperText>
        </FormControl>
    );
};
