import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { FC } from 'react';
import { MeasurementRangeCategoryDto, MeasurementRangeDto } from '../../dtos';
import { MeasurementRangeSection } from './MeasurementRangeSection';
import { MeasurementRangeValidationFields } from './useMeasurementRangeFormManager';

export interface IMeasurementRangeCategorySectionProps {
    category: MeasurementRangeCategoryDto;
    onRangeUpdated: (measurementIdx: number) => (rangeIdx: number) => (updatedRange: MeasurementRangeDto) => void;
    onAddRangeClicked: (measurementIdx: number) => void;
    onRemoveRangeClicked: (measurementIdx: number) => (rangeIdx: number) => (updatedRange: MeasurementRangeDto) => void;
    rangeErrorMessages?: Map<number, Map<number, Map<MeasurementRangeValidationFields, string>>>;
}

export const MeasurementRangeCategorySection: FC<IMeasurementRangeCategorySectionProps> = ({
    category,
    onRangeUpdated,
    onAddRangeClicked,
    onRemoveRangeClicked,
    rangeErrorMessages,
}) => {
    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>{category.name}</AccordionSummary>
            <AccordionDetails>
                <Box display='flex' flexDirection='column' gap={2}>
                    {category.measurementRanges.map((measurement, index) => (
                        <MeasurementRangeSection
                            key={`${measurement.id}-${index}`}
                            measurement={measurement}
                            onChange={onRangeUpdated(index)}
                            onDelete={onRemoveRangeClicked(index)}
                            onAdd={() => onAddRangeClicked(index)}
                            errorMessages={rangeErrorMessages?.get(index)}
                        />
                    ))}
                    {category.measurementRanges.length === 0 && (
                        <Box width='100%' textAlign='center'>
                            <Typography>
                                No Measurements are defined in this Category. Go to the Measurement Names page to add some.
                            </Typography>
                        </Box>
                    )}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};
