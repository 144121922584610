import { Grid, Paper, Typography } from '@mui/material';
import { FC } from 'react';

export const NonChatHome: FC = () => {
    return (
        <Grid container direction='column'>
            <Grid item container direction='column' justifyContent='start' xs={6}>
                <Paper sx={{ margin: '24px', borderRadius: '12px', textAlign: 'center', p: 2 }}>
                    <Typography variant='h2'>Welcome To Yoo Data Direct</Typography>
                </Paper>
            </Grid>
        </Grid>
    );
};