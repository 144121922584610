import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { FormValidationMethod, IFormFieldValidationConfig, createValidatorConfig, isNotBlank, runFormValidation } from '../../Components/CoreLib/library';
import { MeasurementCategoryDto, MeasurementDto } from '../../dtos';
import { emptyGuid } from '../../util';

export const DEFAULT_MEASUREMENT: MeasurementCategoryDto = {
    id: emptyGuid,
    name: '',
    description: '',
    measurements: []
};

type FormValidationConfig<T> = Map<keyof T, IFormFieldValidationConfig>;

const CATEGORY_VALIDATION_CONFIG: FormValidationConfig<MeasurementCategoryDto> = new Map([
    ['name', createValidatorConfig([isNotBlank], 'Name')]
]);

export function useMeasurementCategoryFormManager(
    initialMeasurementCategories: MeasurementCategoryDto[],
    save: (values: MeasurementCategoryDto[]) => void
) {
    const [measurementCategories, setMeasurementCategories] = useState<MeasurementCategoryDto[]>(initialMeasurementCategories);
    const [isAddingCategory, setIsAddingCategory] = useState(false);
    const [deletingCategoryName, setDeletingCategoryName] = useState('');
    const [categoryErrorMessages, setCategoryErrorMessages] = useState(new Map<number, Map<keyof MeasurementCategoryDto, string>>());
    const [measurementErrorMessages, setMeasurementErrorMessages] = useState(new Map<number, Map<number, Map<keyof MeasurementDto, string>>>());

    const updateCategory = useCallback(
        (idx: number) => (updatedMeasurement: MeasurementCategoryDto) => {
            setMeasurementCategories((current) => {
                var updatedList = _.cloneDeep(current);
                updatedList[idx] = updatedMeasurement;
                return _.orderBy(updatedList, ['name'], ['asc'])
            });
        },
        []
    );

    const isUniqueMeasurementName: FormValidationMethod = useCallback((value) => {
        var isValid = false;
        if (!value || typeof value !== 'string') {
            isValid = true;
        } else {
            isValid = measurementCategories.flatMap((m) => m.measurements).filter((m) => m.friendlyName === value).length <= 1;
        }

        var errorMessageBuilder = (fieldName: string) => `${fieldName} must be unique`;

        return {
            isValid,
            errorMessageBuilder
        }
    }, [measurementCategories])

    const MEASUREMENT_VALIDATION_CONFIG: FormValidationConfig<MeasurementDto> = useMemo(() => new Map([
        ['friendlyName', createValidatorConfig([isNotBlank, isUniqueMeasurementName], 'Friendly Name')],
        ['aliases', createValidatorConfig([isNotBlank], 'Aliases')],
    ]), [isUniqueMeasurementName]);

    const validate = useCallback(() => {
        var allCategoryErrors = new Map<number, Map<keyof MeasurementCategoryDto, string>>();
        var allMeasurementErrors = new Map<number, Map<number, Map<keyof MeasurementDto, string>>>();
        var validationResults: boolean[] = [];
        measurementCategories.forEach((category, categoryIdx) => {
            var categoryValidationResult = runFormValidation(category, CATEGORY_VALIDATION_CONFIG);
            validationResults.push(categoryValidationResult.isValid);
            allCategoryErrors.set(categoryIdx, categoryValidationResult.errorMessages);
            var measurementErrors = new Map<number, Map<keyof MeasurementDto, string>>();
            category.measurements.forEach((measurement, measurementIdx) => {
                var measurementValidationResult = runFormValidation(measurement, MEASUREMENT_VALIDATION_CONFIG);
                validationResults.push(measurementValidationResult.isValid);
                measurementErrors.set(measurementIdx, measurementValidationResult.errorMessages);
            });
            allMeasurementErrors.set(categoryIdx, measurementErrors);
        });

        setCategoryErrorMessages(allCategoryErrors);
        setMeasurementErrorMessages(allMeasurementErrors);
        return validationResults.every((vr) => vr === true);
    }, [measurementCategories, MEASUREMENT_VALIDATION_CONFIG]);

    const addNewCategory = useCallback((newSectionName: string) => {
        setMeasurementCategories((current) => _.orderBy([...current, { ...DEFAULT_MEASUREMENT, name: newSectionName }], ['name'], ['asc']));
    }, []);

    const handleSave = useCallback(() => {
        const isFormValid = validate();
        if (isFormValid) {
            save(measurementCategories);
        }
    }, [validate, measurementCategories, save]);

    const removeSection = useCallback((sectionName: string) => {
        setMeasurementCategories((setMeasurements) => setMeasurements.filter((m) => m.name !== sectionName));
    }, []);

    const handleAddCategoryClicked = useCallback(() => {
        setIsAddingCategory(true);
    }, []);

    const handleCategoryAddConfirm = useCallback(
        (sectionName: string) => {
            addNewCategory(sectionName);
            setIsAddingCategory(false);
        },
        [addNewCategory]
    );

    const handleCategoryAddCancel = useCallback(() => {
        setIsAddingCategory(false);
    }, []);

    const handleCategoryDeleteCancel = useCallback(() => {
        setDeletingCategoryName('');
    }, []);

    const handleCategoryDeleteConfirm = useCallback(() => {
        removeSection(deletingCategoryName);
        setDeletingCategoryName('');
    }, [removeSection, deletingCategoryName]);

    const categoryNameValidator = useCallback(
        (newSectionName: string): string => {
            var existingSectionNamesAllCaps = _.uniq(measurementCategories.map((m) => m.name.toUpperCase()));
            var newSectionNameAllCaps = newSectionName.toUpperCase();
            if (existingSectionNamesAllCaps.includes(newSectionNameAllCaps)) {
                return 'Category name already in use';
            }

            return '';
        },
        [measurementCategories]
    );

    const allUsedAliases = useMemo(() => measurementCategories.flatMap((m) => m.measurements.flatMap((m) => m.aliases)), [measurementCategories]);

    return {
        measurementCategories,
        updateCategory,
        handleAddCategoryClicked,
        handleCategoryAddConfirm,
        handleCategoryAddCancel,
        handleCategoryDeleteCancel,
        handleCategoryDeleteConfirm,
        setDeletingCategoryName,
        isAddingCategory,
        deletingCategoryName,
        handleSave,
        categoryNameValidator,
        categoryErrorMessages,
        measurementErrorMessages,
        allUsedAliases
    };
}
