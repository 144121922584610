import { AccountBox } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { FC, useCallback } from 'react';
import {
    AuthenticatedComponent,
    BasicTransferList,
    FormInput,
    FormSection,
    IFormProps,
    LoadingIndicator,
    SlimFormHeader,
} from '../../Components/CoreLib/library';
import { UserDto } from '../../dtos';
import { emptyGuid } from '../../util/constants';
import { useUserFormManager } from './useUserFormManager';

const DEFAULT_USER: UserDto = {
    firstName: '',
    lastName: '',
    email: '',
    auth0UserId: '',
    phoneNumber: '',
    clientId: emptyGuid,
    displayName: '',
    id: emptyGuid,
    isActive: true,
    createdOn: new Date(),
    isLicenseAccepted: false,
};

export const UserForm: FC<IFormProps<UserDto>> = ({ save, cancel, initValues = DEFAULT_USER, isLoading }) => {
    const {
        formUser,
        isFormDirty,
        fieldErrors,
        validateForm,
        handleTextFieldChange,
        handleToggleActive,
        handleRolesChange,
        currentUserRolesAsTransferListItems,
        allRoleOptionsAsTransferListItems,
        isLoadingUserRoles,
        currentPageTitle,
    } = useUserFormManager(initValues);

    const handleSave = useCallback(() => {
        const isFormValid = validateForm();
        if (isFormValid) {
            save(formUser);
        }
    }, [validateForm, formUser, save]);

    const handleCancel = () => {
        cancel();
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Grid component='form' container direction='column' spacing={1}>
            <SlimFormHeader
                icon={<AccountBox />}
                title={currentPageTitle}
                breadcrumbs={[
                    { label: 'Home', navLink: '/' },
                    { label: 'Admin', navLink: '', isText: true },
                    { label: 'Users', navLink: '/users' },
                ]}
                permissionsTypeName='users'
                isFormDirty={isFormDirty}
                canInactivate={false}
                isActive={formUser.isActive}
                handleIsActiveChange={handleToggleActive}
                handleCancel={handleCancel}
                handleSave={handleSave}
            />
            <Grid item mx={2}>
                <FormSection>
                    <Grid item container direction='row' spacing={2}>
                        <Grid item xs={12} md={6} lg={3}>
                            <FormInput
                                fullWidth
                                label='First Name'
                                name='firstName'
                                value={formUser.firstName}
                                onChange={handleTextFieldChange}
                                errorText={fieldErrors.get('firstName')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <FormInput
                                fullWidth
                                label='Last Name'
                                name='lastName'
                                value={formUser.lastName}
                                onChange={handleTextFieldChange}
                                errorText={fieldErrors.get('lastName')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <FormInput
                                fullWidth
                                label='Email'
                                name='email'
                                value={formUser.email}
                                onChange={handleTextFieldChange}
                                errorText={fieldErrors.get('email')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <FormInput
                                fullWidth
                                label='Phone'
                                name='phoneNumber'
                                value={formUser.phoneNumber}
                                onChange={handleTextFieldChange}
                                errorText={fieldErrors.get('phoneNumber')}
                            />
                        </Grid>
                    </Grid>
                    <AuthenticatedComponent requiredPermissions={['assign:userRoles']}>
                        <Grid item xs={12}>
                            {isLoadingUserRoles ? (
                                <LoadingIndicator />
                            ) : (
                                <BasicTransferList
                                    targetColumnName='Active Roles'
                                    sourceColumnName='Available Roles'
                                    currentItems={currentUserRolesAsTransferListItems}
                                    allItems={allRoleOptionsAsTransferListItems}
                                    onCurrentItemsChanged={handleRolesChange}
                                />
                            )}
                        </Grid>
                    </AuthenticatedComponent>
                </FormSection>
            </Grid>
        </Grid>
    );
};
