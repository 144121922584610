import {FC, useCallback} from 'react';
import { useCreateMeasurementMutation, useGetMeasurementQuery } from '../../store/generated/generatedApi';
import { ApiError, LoadingIndicator, useFailedActionSnackbar, useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../Components/CoreLib/library';
import { useNavigate } from 'react-router-dom';
import { EditMeasurementsForm } from './EditMeasurementsForm';

export const EditMeasurementsView: FC = () => {
    const {data: Measurements, isLoading: isLoadingMeasurements, isError: isErrorLoadingMeasurements, refetch: refetchMeasurements, isFetching: isFetchingMeasurements} = useGetMeasurementQuery();
    useFailedActionSnackbar('load', 'measurements', isErrorLoadingMeasurements);
    const [saveMeasurements, { isLoading: isSaving, isError: isErrorSaving, isSuccess: isSuccessSaving, reset: resetSave }] = useCreateMeasurementMutation();
    useFailedUpdateSnackbar('measurements', isErrorSaving, resetSave);
    useSuccessfulUpdateSnackbar('measurements', isSuccessSaving, resetSave);
    const navigate = useNavigate();

    const handleCancel = useCallback(() => {
        navigate('/');
    }, [navigate]);

    if (isLoadingMeasurements || isFetchingMeasurements) {
        return <LoadingIndicator />
    }

    if (isErrorLoadingMeasurements || !Measurements) {
        return <ApiError onReloadClick={refetchMeasurements} />
    }

    return <EditMeasurementsForm initValues={Measurements.pageResults} save={saveMeasurements} cancel={handleCancel} isLoading={isSaving} />;
};