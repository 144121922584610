import {FC, useCallback, useEffect, useState} from 'react';
import { FormInput, StandardDialogHeader } from '../CoreLib/library';
import { Box, Button, Dialog, DialogActions } from '@mui/material';

export interface ISimpleTextInputPromptProps {
    isVisible: boolean;
    title: string,
    fieldLabel: string,
    onCancel: () => void;
    onConfirm: (value: string) => void;
    icon?: JSX.Element;
    initialValue?: string;
    customValidationMethod?: (currentValue: string) => string;
};

export const SimpleTextInputPrompt: FC<ISimpleTextInputPromptProps> = ({
    isVisible,
    title,
    fieldLabel,
    onCancel,
    onConfirm,
    icon,
    initialValue = '',
    customValidationMethod
}) => {
    const [inputValue, setInputValue] = useState(initialValue);
    const [errorMessage, setErrorMessage] = useState('');
    const handleValueChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
        setErrorMessage('');
    }, []);

    const handleSaveClicked = useCallback(() => {
        if (inputValue.trim() === '') {
            setErrorMessage(`${fieldLabel} is required`);
            return;
        }
        if (customValidationMethod) {
            var customError = customValidationMethod(inputValue);
            if (customError) {
                setErrorMessage(customError);
                return;
            }
        }
        onConfirm(inputValue);
        setErrorMessage('');
    }, [onConfirm, inputValue, fieldLabel, customValidationMethod]);

    // Reset error message whenever we open or close the modal
    useEffect(() => {
        setErrorMessage('')
        if (!isVisible) {
            setInputValue('');
        }
    }, [isVisible]);

    // Reset the input value whenever the initial value changes
    useEffect(() => {
        isVisible && setInputValue(initialValue)
    }, [isVisible, initialValue]);

    return (
        <Dialog open={isVisible}>
            <StandardDialogHeader text={title} icon={icon} />
            <Box px={2}>
                <FormInput
                    value={inputValue}
                    label={fieldLabel}
                    onChange={handleValueChange}
                    errorText={errorMessage}
                    fullWidth
                    required
                />
            </Box>
            <DialogActions>
                <Button onClick={onCancel} fullWidth variant='outlined'>Cancel</Button>
                <Button onClick={handleSaveClicked} fullWidth variant='contained'>Confirm</Button>
            </DialogActions>
        </Dialog>
    );
};