import { useCallback, useEffect, useState } from 'react';
import { SpecialRuleDto } from '../../dtos';
import { emptyGuid } from '../../util';

export function useSpecialRuleFormManager(initialSpecialRules: SpecialRuleDto, save: (values: SpecialRuleDto) => void) {
    const [specialRules, setSpecialRules] = useState<SpecialRuleDto>(initialSpecialRules);
    const [filterOutIds, setFilterOutIds] = useState<string[]>([]);

    useEffect(() => {
        const ids = Object.values(specialRules).filter((id) => id !== emptyGuid);
        setFilterOutIds(ids as string[]);
    }, [specialRules]);

    const handleSave = useCallback(() => {
        save(specialRules);
    }, [save, specialRules]);

    const updateSpecialRule = useCallback((field: keyof SpecialRuleDto, value: string | null) => {
        setSpecialRules((current) => ({
            ...current,
            [field]: value ?? emptyGuid,
        }));
    }, []);

    return {
        handleSave,
        updateSpecialRule,
        specialRules,
        filterOutIds,
    };
}
