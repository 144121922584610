import { Button, Checkbox, FormControlLabel, Link, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { useRecoverUserAcceptUserAgreementMutation } from '../store/generated/generatedApi';

export const LicensePage = () => {
    const [checked, setChecked] = useState(false);
    const [updatedUserAcceptedLicense] = useRecoverUserAcceptUserAgreementMutation();

    const handleOnClick = () => {
        updatedUserAcceptedLicense();
    };

    const label = (
        <Typography>
            Accept {''}
            <Link href='/LicenseAgreement.pdf' download='LicenseAgreement.pdf'>
                License Agreement
            </Link>
        </Typography>
    );

    return (
        <Box p={1} display='flex' alignItems='center' flexDirection='column'>
            <Typography variant='h1'>Welcome </Typography>
            <FormControlLabel control={<Checkbox onChange={(event) => setChecked(event.target.checked)} />} label={label} />
            <Button variant='contained' onClick={handleOnClick} disabled={!checked}>
                Confirm
            </Button>
        </Box>
    );
};
