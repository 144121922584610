import { AddCircle, Delete, Label } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { FC } from 'react';
import { IFormProps, SlimFormHeader } from '../../Components/CoreLib/library';
import { SimpleConfirmationPrompt, SimpleTextInputPrompt } from '../../Components/Global';
import { MeasurementCategoryDto } from '../../dtos';
import { MeasurementCategorySection } from './MeasurementCategorySection';
import { useMeasurementCategoryFormManager } from './useMeasurementCategoryFormManager';

export const EditMeasurementsForm: FC<IFormProps<MeasurementCategoryDto[]>> = ({ initValues = [], save, cancel, isLoading }) => {
    const {
        measurementCategories,
        updateCategory,
        handleAddCategoryClicked,
        handleCategoryAddConfirm,
        handleCategoryAddCancel,
        handleCategoryDeleteCancel,
        handleCategoryDeleteConfirm,
        setDeletingCategoryName,
        isAddingCategory,
        deletingCategoryName,
        handleSave,
        categoryNameValidator,
        categoryErrorMessages,
        measurementErrorMessages,
        allUsedAliases
    } = useMeasurementCategoryFormManager(initValues, save);
    
    return (
        <Grid component='form' container direction='column' spacing={1}>
            <Grid item container sx={{ position: 'sticky', top: 64, zIndex: 3 }}>
                <SlimFormHeader
                    icon={<Label />}
                    title={'Measurement Names'}
                    breadcrumbs={[{ label: 'Home', navLink: '/' }]}
                    permissionsTypeName='measurements'
                    canInactivate={false}
                    handleCancel={cancel}
                    handleSave={handleSave}
                    isLoading={isLoading}
                />
            </Grid>
            <Grid item mx={2} display='flex' flexDirection='column' gap={2}>
                <Box display='flex' flexDirection='column' gap={2}>
                    {measurementCategories.map((category, idx) => (
                        <MeasurementCategorySection
                            key={`${category.id}-${category.name}`}
                            value={category}
                            onChange={updateCategory(idx)}
                            onDeleteClicked={() => setDeletingCategoryName(category.name)}
                            categoryErrorMessages={categoryErrorMessages.get(idx)}
                            measurementErrorMessages={measurementErrorMessages.get(idx)}
                            categoryNameValidator={categoryNameValidator}
                            allUsedAliases={allUsedAliases}
                        />
                    ))}
                </Box>
                <Button fullWidth color='primary' variant='contained' startIcon={<AddCircle />} onClick={handleAddCategoryClicked}>
                    New Category
                </Button>
            </Grid>
            <SimpleTextInputPrompt
                isVisible={isAddingCategory}
                icon={<AddCircle />}
                title='New Category'
                fieldLabel='Name'
                onCancel={handleCategoryAddCancel}
                onConfirm={handleCategoryAddConfirm}
                customValidationMethod={categoryNameValidator}
            />
            <SimpleConfirmationPrompt
                isVisible={!!deletingCategoryName}
                icon={<Delete />}
                title='Delete Category'
                message={`Are you sure you would like to delete the ${deletingCategoryName} category`}
                onCancel={handleCategoryDeleteCancel}
                onConfirm={handleCategoryDeleteConfirm}
            />
        </Grid>
    )
};