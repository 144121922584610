import { useCallback, useState } from 'react';
import { PanelDto } from '../../dtos';
import _ from 'lodash';
import { emptyGuid } from '../../util';

export const DEFAULT_PANEL: PanelDto = {
    name: '',
    measurements: [],
    defaultDescription: '',
    id: emptyGuid,
};
export function usePanelFormManager(initialPanels: PanelDto[], save: (values: PanelDto[]) => void) {
    const [panels, setPanels] = useState<PanelDto[]>(initialPanels);

    const addNewPanel = useCallback(() => {
        setPanels((current) => {
            var currentPanels = _.cloneDeep(current);
            currentPanels.push({ ...DEFAULT_PANEL });
            return currentPanels;
        });
    }, []);

    const handleSave = useCallback(() => {
        save(panels);
    }, [save, panels]);

    const updatePanel = useCallback((panel: PanelDto, index: number) => {
        setPanels((current) => {
            var updatedPanels = _.cloneDeep(current);
            updatedPanels[index] = panel;
            return updatedPanels;
        });
    }, []);

    const handleDeleteRow = useCallback((index: number) => {
        setPanels((current) => {
            var updatedPanels = _.cloneDeep(current);
            updatedPanels.splice(index, 1);
            return updatedPanels;
        });
    }, []);

    return {
        panels,
        handleSave,
        addNewPanel,
        updatePanel,
        handleDeleteRow,
    };
}
