import { FC, useCallback } from 'react';
import { useCreatePanelMutation, useGetPanelQuery } from '../../store/generated/generatedApi';
import { ApiError, LoadingIndicator, useFailedActionSnackbar, useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../Components/CoreLib/library';
import { useNavigate } from 'react-router-dom';
import { EditPanelsForm } from './EditPanelsForm';
export const EditPanelsView: FC = () => {
    const {
        data: Panels,
        isLoading: isLoadingPanels,
        isError: isErrorLoadingPanels,
        refetch: refetchPanels,
        isFetching: isFetchingPanels,
    } = useGetPanelQuery();

    useFailedActionSnackbar('load', 'panels', isErrorLoadingPanels);
    const [savePanels, { isLoading: isSaving, isError: isErrorSaving, isSuccess: isSuccessSaving, reset: resetSave }] = useCreatePanelMutation();
    useFailedUpdateSnackbar('panels', isErrorSaving, resetSave);
    useSuccessfulUpdateSnackbar('panels', isSuccessSaving, resetSave);
    const navigate = useNavigate();

    const handleCancel = useCallback(() => {
        navigate('/');
    }, [navigate]);

    if (isLoadingPanels || isFetchingPanels) {
        return <LoadingIndicator />;
    }

    if (isErrorLoadingPanels || !Panels) {
        return <ApiError onReloadClick={refetchPanels} />;
    }

    return <EditPanelsForm initValues={Panels.pageResults} save={savePanels} cancel={handleCancel} isLoading={isSaving} />;
};
