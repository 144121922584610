import { Autocomplete, FormControl, FormHelperText, IconButton, TextField } from '@mui/material';
import { FC, useState } from 'react';
import { useGetRangeUnitsQuery } from '../../store/generated/generatedApi';
import AddCircleIcon from '@mui/icons-material/AddCircle';

export interface IUnitsSelectProps {
    value: string;
    onChange: (updatedValue: string) => void;
    errorText?: string;
    label?: string;
    required?: boolean;
    fullWidth?: boolean;
}

export const UnitsSelect: FC<IUnitsSelectProps> = ({ value, onChange, errorText = '', label = 'Units', required, fullWidth }) => {
    const { data: knownUnits, isLoading: isLoadingUnits } = useGetRangeUnitsQuery();
    const [unitName, setUnitName] = useState('');
    return (
        <FormControl required={required} fullWidth={fullWidth} error={!!errorText}>
            <Autocomplete
                loading={isLoadingUnits}
                selectOnFocus
                clearOnBlur
                value={value}
                options={knownUnits?.pageResults || []}
                onChange={(_, newValue) => onChange(newValue?.toString() ?? '')}
                renderInput={(params) => <TextField {...params} error={!!errorText} />}
                onInputChange={(event, newInputValue) => {
                    setUnitName(newInputValue);
                }}
                filterOptions={(options, params) => {
                    var searchText = params.inputValue.toLowerCase();
                    let filtered = options.filter((option) => option.toLowerCase().includes(searchText));
                    if (unitName !== '' && !knownUnits?.pageResults.includes(unitName)) {
                        filtered.push(unitName);
                    }
                    return filtered;
                }}
                renderOption={(props, option) => (
                    <li {...props}>
                        {typeof option === 'string' && !knownUnits?.pageResults.includes(option) ? (
                            <>
                                <IconButton edge='start' size='small' color='primary'>
                                    <AddCircleIcon />
                                </IconButton>
                                {`Add: ${option}`}
                            </>
                        ) : (
                            option
                        )}
                    </li>
                )}
            />
            <FormHelperText>{errorText}</FormHelperText>
        </FormControl>
    );
};
