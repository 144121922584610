import { Delete } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useState } from 'react';
import { AliasesSelect } from '../../Components/CommonInputs';
import { FormInput } from '../../Components/CoreLib/library';
import { SimpleConfirmationPrompt } from '../../Components/Global';
import { MeasurementDto } from '../../dtos';
import { useMeasurementFormManager } from './useMeasurementFormManager';

export interface IMeasurementCategoryMeasurementRowProps {
    measurement: MeasurementDto;
    onChange: (updatedMeasurement: MeasurementDto) => void;
    onDelete: () => void;
    errorMessages?: Map<keyof MeasurementDto, string>;
    allUsedAliases: string[];
}

export const MeasurementCategoryMeasurementRow: FC<IMeasurementCategoryMeasurementRowProps> = ({
    measurement,
    onChange,
    onDelete,
    errorMessages,
    allUsedAliases,
}) => {
    const { handleAliasesChanged, handleMeasurementChanged, handleDescriptionChanged } = useMeasurementFormManager(measurement, onChange);
    const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState(false);

    return (
        <Box display='flex' gap={1} width='100%'>
            <FormInput
                label='Friendly Name'
                required
                value={measurement.friendlyName}
                onChange={handleMeasurementChanged}
                errorText={errorMessages?.get('friendlyName')}
                fullWidth
            />
            <FormInput
                label='Description'
                value={measurement.description}
                onChange={handleDescriptionChanged}
                errorText={errorMessages?.get('description')}
                fullWidth
            />
            <AliasesSelect
                value={measurement.aliases}
                onChange={handleAliasesChanged}
                errorText={errorMessages?.get('aliases')}
                fullWidth
                required
                allUsedAliases={allUsedAliases}
            />
            <Box alignSelf='center'>
                <IconButton onClick={() => setIsDeleteConfirmationVisible(true)}>
                    <Delete />
                </IconButton>
            </Box>
            <SimpleConfirmationPrompt
                isVisible={isDeleteConfirmationVisible}
                icon={<Delete />}
                title='Delete Category'
                message={`Are you sure you would like to delete this measurement`}
                onCancel={() => setIsDeleteConfirmationVisible(false)}
                onConfirm={onDelete}
            />
        </Box>
    );
};
