import { AddUserView, EditMeasurementRangesView, EditMeasurementsView, EditUserView, Home, UsersListView } from '../Views';
import { EditPanelsView } from '../Views/Panels/EditPanelsView';
import { EditSpecialRulesView } from '../Views/SpecialRules';

export interface IRoute {
    component: any;
    path: string;
    routes?: IRoute[];
    requiredPermissions?: string[];
    permissionLogic?: 'and' | 'or';
}

export const routes: IRoute[] = [
    {
        component: <Home />,
        path: '/',
        requiredPermissions: [],
    },

    {
        component: <UsersListView />,
        path: '/users',
        requiredPermissions: ['read:users'],
    },
    {
        component: <AddUserView />,
        path: '/user/create',
        requiredPermissions: ['create:users'],
    },
    {
        component: <EditUserView />,
        path: '/user/:id',
        requiredPermissions: ['edit:users'],
    },
    {
        component: <EditMeasurementsView />,
        path: '/measurements',
        requiredPermissions: ['edit:measurements'],
    },
    {
        component: <EditMeasurementRangesView />,
        path: '/ranges',
        requiredPermissions: ['edit:measurements'],
    },
    {
        component: <EditPanelsView />,
        path: '/panels',
        requiredPermissions: ['edit:panels'],
    },
    {
        component: <EditSpecialRulesView />,
        path: '/special-rules',
        requiredPermissions: ['edit:special-rules'],
    },
];

export const publicRoutes: IRoute[] = [];
