import { useCallback } from 'react';
import { EditSpecialRulesForm } from './EditSpecialRulesForm';
import { useNavigate } from 'react-router-dom';
import { useCreateSpecialRuleMutation, useGetSpecialRuleQuery } from '../../store/generated/generatedApi';
import { ApiError, LoadingIndicator, useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../Components/CoreLib/library';

export const EditSpecialRulesView = () => {
    const navigate = useNavigate();

    const handleOnCancel = useCallback(() => {
        navigate('/');
    }, [navigate]);

    const [saveSpecialRules, { isError: isErrorSaving, isSuccess: isSuccessSaving, reset: resetSave }] = useCreateSpecialRuleMutation();

    const {
        data: specialRules,
        isLoading: isLoadingSpecialRules,
        isError: isErrorLoadingSpecialRules,
        refetch: refetchSpecialRules,
        isFetching: isFetchingSpecialRules,
    } = useGetSpecialRuleQuery();

    useFailedUpdateSnackbar('special rules', isErrorSaving, resetSave);
    useSuccessfulUpdateSnackbar('special rules', isSuccessSaving, resetSave);

    if (isLoadingSpecialRules || isFetchingSpecialRules) {
        return <LoadingIndicator />;
    }

    if (isErrorLoadingSpecialRules || !specialRules) {
        return <ApiError onReloadClick={refetchSpecialRules} />;
    }

    console.log(specialRules);

    return <EditSpecialRulesForm initValues={specialRules} save={saveSpecialRules} cancel={handleOnCancel} isLoading={isLoadingSpecialRules} />;
};
