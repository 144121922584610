import { serverApi } from '../api';
import _ from 'lodash';
import {
	MessageDto,
	AddClientRequest,
	GenerateLabMessageRequestDto,
	CalculatePhenotypicAgeRequestDto,
	SavePhenotypicAgeRequestDto,
	MeasurementCategoryDto,
	PanelDto,
	MeasurementRangeCategoryDto,
	SpecialRuleDto,
	UserDto,
	MessageContentResponseDto,
	GenerateLabMessageResponseDto,
	CalculatePhenotypicAgeResponseDto,
	QueryResultsDto,
	BulkUpdateResultDto,
	UserRoleDto
} from '../../dtos';

import {
	GetChatByIdQueryParameters,
	GetChatFileByIdQueryParameters,
	GetMeasurementAliasesQueryParameters,
	GetRangeUnitsQueryParameters,
	GetUserQueryParameters,
	GetUserByIdQueryParameters,
	ArchiveUserByIdQueryParameters,
	RecoverUserByIdQueryParameters
} from './generatedParameterTypes';

const buildQueryString = (obj: any, routeParams: string[] = []) => {
	var args = _.omitBy(_.clone(obj), _.isNil);
	routeParams.forEach(rp => delete args[rp]);
	return new URLSearchParams(args).toString();
}

const generatedApi = serverApi.injectEndpoints({
	endpoints: (builder) => ({
		createChat: builder.mutation<void, MessageDto>({
			query: (args) => ({
				url: `/api/Chat`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['Chat']
		}),
		getChatById: builder.query<MessageContentResponseDto[], GetChatByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Chat/${args.threadId}`,
				method: 'GET',
			}),
			providesTags: ['Chat']
		}),
		getChatFileById: builder.query<void, GetChatFileByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Chat/${args.threadId}/file/${args.fileId}`,
				method: 'GET',
			}),
			providesTags: ['Chat']
		}),
		createClient: builder.mutation<void, AddClientRequest>({
			query: (args) => ({
				url: `/api/Client`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['Client']
		}),
		createLabResultsMessage: builder.mutation<GenerateLabMessageResponseDto, GenerateLabMessageRequestDto>({
			query: (args) => ({
				url: `/api/LabResults/message`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['LabResults']
		}),
		createLabResultsPhenotypicAge: builder.mutation<CalculatePhenotypicAgeResponseDto, CalculatePhenotypicAgeRequestDto>({
			query: (args) => ({
				url: `/api/LabResults/phenotypic-age`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['LabResults']
		}),
		createLabResultsPhenotypicAgeSave: builder.mutation<void, SavePhenotypicAgeRequestDto>({
			query: (args) => ({
				url: `/api/LabResults/phenotypic-age/save`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['LabResults']
		}),
		createMeasurement: builder.mutation<void, MeasurementCategoryDto[]>({
			query: (args) => ({
				url: `/api/Measurement`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['Measurement','Range']
		}),
		getMeasurement: builder.query<QueryResultsDto<MeasurementCategoryDto>, void>({
			query: () => ({
				url: `/api/Measurement`,
				method: 'GET',
			}),
			providesTags: ['Measurement']
		}),
		getMeasurementAliases: builder.query<QueryResultsDto<String>, GetMeasurementAliasesQueryParameters>({
			query: (args) => ({
				url: `/api/Measurement/aliases?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['Measurement']
		}),
		createPanel: builder.mutation<void, PanelDto[]>({
			query: (args) => ({
				url: `/api/Panel`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['Panel']
		}),
		getPanel: builder.query<QueryResultsDto<PanelDto>, void>({
			query: () => ({
				url: `/api/Panel`,
				method: 'GET',
			}),
			providesTags: ['Panel']
		}),
		createRange: builder.mutation<void, MeasurementRangeCategoryDto[]>({
			query: (args) => ({
				url: `/api/Range`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['Range']
		}),
		getRange: builder.query<QueryResultsDto<MeasurementRangeCategoryDto>, void>({
			query: () => ({
				url: `/api/Range`,
				method: 'GET',
			}),
			providesTags: ['Range']
		}),
		getRangeUnits: builder.query<QueryResultsDto<String>, GetRangeUnitsQueryParameters>({
			query: (args) => ({
				url: `/api/Range/units?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['Range']
		}),
		createSpecialRule: builder.mutation<void, SpecialRuleDto>({
			query: (args) => ({
				url: `/api/SpecialRule`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['SpecialRule']
		}),
		getSpecialRule: builder.query<SpecialRuleDto, void>({
			query: () => ({
				url: `/api/SpecialRule`,
				method: 'GET',
			}),
			providesTags: ['SpecialRule']
		}),
		getUser: builder.query<QueryResultsDto<UserDto>, GetUserQueryParameters>({
			query: (args) => ({
				url: `/api/User?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['User']
		}),
		createUser: builder.mutation<UserDto, UserDto>({
			query: (args) => ({
				url: `/api/User`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['User']
		}),
		updateUser: builder.mutation<void, UserDto>({
			query: (args) => ({
				url: `/api/User`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['User']
		}),
		getUserById: builder.query<UserDto, GetUserByIdQueryParameters>({
			query: (args) => ({
				url: `/api/User/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['User']
		}),
		archiveUserById: builder.mutation<void, ArchiveUserByIdQueryParameters>({
			query: (args) => ({
				url: `/api/User/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['User']
		}),
		recoverUserById: builder.mutation<void, RecoverUserByIdQueryParameters>({
			query: (args) => ({
				url: `/api/User/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['User']
		}),
		userBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/User/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['User']
		}),
		userBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/User/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['User']
		}),
		getUserMe: builder.query<UserDto, void>({
			query: () => ({
				url: `/api/User/me`,
				method: 'GET',
			}),
			providesTags: ['User']
		}),
		recoverUserAcceptUserAgreement: builder.mutation<void, void>({
			query: () => ({
				url: `/api/User/acceptUserAgreement`,
				method: 'PATCH',
			}),
			invalidatesTags: ['User']
		}),
		getUserPermissions: builder.query<string[], void>({
			query: () => ({
				url: `/api/User/permissions`,
				method: 'GET',
			}),
			providesTags: ['User']
		}),
		getUserRoles: builder.query<UserRoleDto[], void>({
			query: () => ({
				url: `/api/User/roles`,
				method: 'GET',
			}),
			providesTags: ['User']
		}),
	})
});

export const {
	useCreateChatMutation,
	useGetChatByIdQuery,
	useLazyGetChatByIdQuery,
	useGetChatFileByIdQuery,
	useLazyGetChatFileByIdQuery,
	useCreateClientMutation,
	useCreateLabResultsMessageMutation,
	useCreateLabResultsPhenotypicAgeMutation,
	useCreateLabResultsPhenotypicAgeSaveMutation,
	useCreateMeasurementMutation,
	useGetMeasurementQuery,
	useLazyGetMeasurementQuery,
	useGetMeasurementAliasesQuery,
	useLazyGetMeasurementAliasesQuery,
	useCreatePanelMutation,
	useGetPanelQuery,
	useLazyGetPanelQuery,
	useCreateRangeMutation,
	useGetRangeQuery,
	useLazyGetRangeQuery,
	useGetRangeUnitsQuery,
	useLazyGetRangeUnitsQuery,
	useCreateSpecialRuleMutation,
	useGetSpecialRuleQuery,
	useLazyGetSpecialRuleQuery,
	useGetUserQuery,
	useLazyGetUserQuery,
	useCreateUserMutation,
	useUpdateUserMutation,
	useGetUserByIdQuery,
	useLazyGetUserByIdQuery,
	useArchiveUserByIdMutation,
	useRecoverUserByIdMutation,
	useUserBulkArchiveMutation,
	useUserBulkUnarchiveMutation,
	useGetUserMeQuery,
	useLazyGetUserMeQuery,
	useRecoverUserAcceptUserAgreementMutation,
	useGetUserPermissionsQuery,
	useLazyGetUserPermissionsQuery,
	useGetUserRolesQuery,
	useLazyGetUserRolesQuery
} = generatedApi;