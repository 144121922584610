import { useCallback, useMemo } from 'react';
import {
    IFormFieldValidationConfig,
    ITransferListItem,
    createValidatorConfig,
    isNotBlank,
    isShorterThanMaxLength,
    isValidEmail,
    useStandardValidatedFormManager,
} from '../../Components/CoreLib/library';
import { UserDto } from '../../dtos';
import { UserRoleDto } from '../../dtos/generated/UserRoleDto';
import { useGetUserRolesQuery } from '../../store/generated/generatedApi';

type FormValidationConfig<T> = Map<keyof T, IFormFieldValidationConfig>;

const USER_VALIDATION_CONFIG: FormValidationConfig<UserDto> = new Map([
    ['firstName', createValidatorConfig([isNotBlank, isShorterThanMaxLength(250)], 'First Name')],
    ['lastName', createValidatorConfig([isNotBlank, isShorterThanMaxLength(250)], 'Last Name')],
    ['email', createValidatorConfig([isNotBlank, isShorterThanMaxLength(250), isValidEmail], 'Email')],
    ['phoneNumber', createValidatorConfig([isShorterThanMaxLength(25)], 'Phone Number')],
]);

export function useUserFormManager(initialUser: UserDto) {
    const { data: allUserRoles, isLoading: isLoadingUserRoles } = useGetUserRolesQuery();
    const {
        formRecord: formUser,
        setFormRecord: setFormUser,
        isFormDirty,
        fieldErrors,
        validateForm,
        handleTextFieldChange,
        resetForm,
    } = useStandardValidatedFormManager(initialUser, USER_VALIDATION_CONFIG);

    const handleToggleActive = useCallback((isActive: boolean) => setFormUser((currentUser) => ({ ...currentUser, isActive })), [setFormUser]);
    const handleRolesChange = useCallback(
        (updatedRoles: ITransferListItem[]) => setFormUser((currentUser) => ({ ...currentUser, roles: convertTransferListItemsToRoles(updatedRoles) })),
        [setFormUser]
    );
    const currentUserRolesAsTransferListItems = useMemo(() => convertRolesToTransferListItems(formUser.roles ?? []), [formUser]);
    const allRoleOptionsAsTransferListItems = useMemo(() => convertRolesToTransferListItems(allUserRoles ?? []), [allUserRoles]);
    const currentPageTitle = useMemo(() => {
        var fullName = `${formUser.firstName} ${formUser.lastName}`;
        var isFullNameBlank = fullName.trim() === '';
        return isFullNameBlank ? 'New User' : fullName;
    }, [formUser]);

    return {
        formUser,
        isFormDirty,
        fieldErrors,
        validateForm,
        handleTextFieldChange,
        resetForm,
        handleToggleActive,
        handleRolesChange,
        currentUserRolesAsTransferListItems,
        allRoleOptionsAsTransferListItems,
        isLoadingUserRoles,
        currentPageTitle,
    };
}

function convertRolesToTransferListItems(items: UserRoleDto[]): ITransferListItem[] {
    return items.map((item) => ({
        label: item.name,
        value: item.id,
        description: item.description,
    }));
}

function convertTransferListItemsToRoles(items: ITransferListItem[]): UserRoleDto[] {
    return items.map((item) => ({
        id: item.value,
        name: item.label,
        description: item.description ?? '',
    }));
}
