import _ from 'lodash';
import { useCallback, useState } from 'react';
import { IFormFieldValidationConfig, createValidatorConfig, isGreaterThanOrEqualTo, isNotBlank, runFormValidation } from '../../Components/CoreLib/library';
import { MeasurementRangeCategoryDto, MeasurementRangeDto } from '../../dtos';

export const DEFAULT_RANGE: MeasurementRangeDto = {
    isMale: false,
    isFemale: false,
    units: '',
};

type FormValidationConfig<T> = Map<keyof T, IFormFieldValidationConfig>;

export type MeasurementRangeValidationFields = keyof MeasurementRangeDto | 'compositeKey' | 'genderCodes';

const RANGE_VALIDATION_CONFIG: FormValidationConfig<MeasurementRangeDto> = new Map([
    ['units', createValidatorConfig([isNotBlank], 'Units')],
    ['minAge', createValidatorConfig([isGreaterThanOrEqualTo(0)], 'Minimum Age')],
    ['maxAge', createValidatorConfig([isGreaterThanOrEqualTo(0)], 'Maximum Age')],
]);

export function useMeasurementRangeFormManager(
    initialMeasurementCategories: MeasurementRangeCategoryDto[],
    save: (values: MeasurementRangeCategoryDto[]) => void
) {
    const [measurementRangeCategories, setMeasurementRangeCategories] = useState<MeasurementRangeCategoryDto[]>(initialMeasurementCategories);
    const [rangeErrorMessages, setRangeErrorMessages] = useState(new Map<number, Map<number, Map<number, Map<MeasurementRangeValidationFields, string>>>>());

    const validate = useCallback(() => {
        let isValid = true;
        const newErrorMessages = new Map<number, Map<number, Map<number, Map<MeasurementRangeValidationFields, string>>>>();
        measurementRangeCategories.forEach((category, categoryIdx) => {
            const categoryErrorMessages = new Map<number, Map<number, Map<MeasurementRangeValidationFields, string>>>();
            category.measurementRanges.forEach((measurement, measurementIdx) => {
                const measurementErrorMessages = new Map<number, Map<MeasurementRangeValidationFields, string>>();
                measurement.ranges.forEach((range, rangeIdx) => {
                    const rangeValidationResult = runFormValidation(range, RANGE_VALIDATION_CONFIG);
                    if (!rangeValidationResult.isValid) {
                        isValid = false;
                    }
                    const rangeErrorMessages: Map<MeasurementRangeValidationFields, string> = rangeValidationResult.errorMessages;
                    measurementErrorMessages.set(rangeIdx, rangeErrorMessages);
                });
                categoryErrorMessages.set(measurementIdx, measurementErrorMessages);
            });
            newErrorMessages.set(categoryIdx, categoryErrorMessages);
        });
        setRangeErrorMessages(newErrorMessages);
        return isValid;
    }, [measurementRangeCategories]);

    const updateRange = useCallback(
        (categoryIdx: number) => (measurementIdx: number) => (rangeIdx: number) => (updatedRange: MeasurementRangeDto) => {
            setMeasurementRangeCategories((current) => {
                var updatedMeasurementRanges = _.cloneDeep(current);
                updatedMeasurementRanges[categoryIdx].measurementRanges[measurementIdx].ranges[rangeIdx] = updatedRange;
                return updatedMeasurementRanges;
            });
        },
        []
    );

    const addNewRange = useCallback(
        (categoryIdx: number) => (measurementIdx: number) => {
            setMeasurementRangeCategories((current) => {
                var updatedMeasurementRanges = _.cloneDeep(current);
                updatedMeasurementRanges[categoryIdx].measurementRanges[measurementIdx].ranges.push({ ...DEFAULT_RANGE });
                return updatedMeasurementRanges;
            });
        },
        []
    );

    const removeRange = useCallback(
        (categoryIdx: number) => (measurementIdx: number) => (rangeIdx: number) => (updatedRange: MeasurementRangeDto) => {
            setMeasurementRangeCategories((current) => {
                var updatedMeasurementRanges = _.cloneDeep(current);
                updatedMeasurementRanges[categoryIdx].measurementRanges[measurementIdx].ranges.splice(rangeIdx, 1);
                return updatedMeasurementRanges;
            });
        },
        []
    );

    const checkHighLowRanges = useCallback(() => {
        const updatedMeasurementRangeCategories = _.cloneDeep(measurementRangeCategories);

        updatedMeasurementRangeCategories.forEach((category) => {
            category.measurementRanges.forEach((measurement) => {
                measurement.ranges.forEach((range) => {
                    if (range.low == null) {
                        range.lowDescription = '';
                        range.inRangeDescription = '';
                    }
                    if (range.high == null) {
                        range.highDescription = '';
                        range.inRangeDescription = '';
                    }
                });
            });
        });

        return updatedMeasurementRangeCategories;
    }, [measurementRangeCategories]);

    const handleSave = useCallback(() => {
        const isFormValid = validate();
        if (isFormValid) {
            const updatedMeasurementRanges = checkHighLowRanges();
            save(updatedMeasurementRanges);
        }
    }, [validate, save, checkHighLowRanges]);

    return {
        measurementRangeCategories,
        rangeErrorMessages,
        updateRange,
        addNewRange,
        removeRange,
        handleSave,
    };
}
