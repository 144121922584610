import { Delete } from '@mui/icons-material';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import { FC } from 'react';
import { PanelDto } from '../../dtos';
import { FormInput } from '../../Components/CoreLib/library';
import { usePanelRowFormManager } from './usePanelRowFormManager';
import { MeasurementSelect } from '../../Components/CommonInputs/MeasurementSelect';

export interface IPanelSectionProps {
    panel: PanelDto;
    idx: number;
    updatePanel: (updatedPanel: PanelDto, idx: number) => void;
    handleDeleteRow: (idx: number) => void;
}

export const PanelSection: FC<IPanelSectionProps> = ({ panel, idx, updatePanel, handleDeleteRow }) => {
    const { handleDescriptionChanged, handleNameChanged, handleAutoCompleteChanged } = usePanelRowFormManager(panel, idx, updatePanel);

    return (
        <Box display='flex' flexDirection='row' width='100%' alignItems='center'>
            <Box display='flex' flexDirection='column' flexGrow={1}>
                <Box display='flex' flexDirection='row' gap={1}>
                    <Box flex={1}>
                        <FormInput label='Name' type='string' value={panel.name?.toString()} onChange={handleNameChanged} fullWidth />
                    </Box>
                    <Box flex={1}>
                        <MeasurementSelect fullWidth onChange={handleAutoCompleteChanged} initialMeasurements={panel.measurements} />
                    </Box>
                </Box>
                <Box width='100%' pt={1} display='flex' flexDirection='column'>
                    <Typography>Description</Typography>
                    <TextField value={panel.defaultDescription.toString()} onChange={handleDescriptionChanged} fullWidth multiline />
                </Box>
            </Box>
            <Box>
                <IconButton>
                    <Delete onClick={() => handleDeleteRow(idx)} />
                </IconButton>
            </Box>
        </Box>
    );
};
