import { Box } from '@mui/system';
import { FC } from 'react';

import { MeasurementSingleSelect } from '../../Components/CommonInputs/MeasurementSingleSelect';

interface ISpecialRuleSectionProps {
    measurement: string;
    initialMeasurementId: string;
    onMeasurementChange: (measurementId: string) => void;
    filterOutIds: string[];
}

export const SpecialRuleSection: FC<ISpecialRuleSectionProps> = ({ measurement, initialMeasurementId, onMeasurementChange, filterOutIds }) => {
    return (
        <Box display='flex' flexDirection='row' width='100%' flexGrow={1} alignItems='center'>
            <Box flex={0.25}>
                <Box>{measurement}</Box>
            </Box>
            <Box flex={1}>
                <MeasurementSingleSelect
                    fullWidth
                    filterOutIds={filterOutIds}
                    initialMeasurementId={initialMeasurementId}
                    onMeasurementChange={onMeasurementChange}
                />
            </Box>
        </Box>
    );
};
