import { AccountBox } from '@mui/icons-material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTableColumn, PaginatedProps, StandardListViewV2 } from '../../Components/CoreLib/library';
import { UserDto } from '../../dtos';
import { useGetUserQuery, useUserBulkArchiveMutation, useUserBulkUnarchiveMutation } from '../../store/generated/generatedApi';

const defaultPaginationProps: PaginatedProps = {
    sortKey: 'FIRST_NAME',
    sortAsc: true,
    page: 0,
    pageSize: 25,
};

export const UsersListView: FC = () => {
    const navigate = useNavigate();

    const tableColumns: DataTableColumn<UserDto>[] = [
        { key: 'firstName', label: 'First Name', sortKey: 'FIRST_NAME' },
        { key: 'lastName', label: 'Last Name', sortKey: 'LAST_NAME' },
        { key: 'email', label: 'Email', sortKey: 'EMAIL' },
        { key: 'phoneNumber', label: 'Phone', sortKey: 'PHONE_NUMBER' },
    ];

    const handleCreateNew = () => {
        navigate('/user/create');
    };

    const handleTableEdit = (id: string) => {
        navigate(`/user/${id}`);
    };

    return (
        <StandardListViewV2
            headerIcon={<AccountBox />}
            headerTitle='Users'
            breadCrumbs={[
                { label: 'Home', navLink: '/' },
                { label: 'Admin', navLink: '', isText: true },
            ]}
            defaultPaginationProps={defaultPaginationProps}
            getDataQuery={useGetUserQuery}
            isShowActiveToggleVisible={true}
            inactivateMutation={useUserBulkArchiveMutation}
            activateMutation={useUserBulkUnarchiveMutation}
            tableColumns={tableColumns}
            entityNameSingular='User'
            permissionName='users'
            handleAdd={handleCreateNew}
            handleEdit={handleTableEdit}
        />
    );
};
