import { FC } from 'react';
import { MeasurementRangeDto } from '../../dtos';
import { Box, FormHelperText, Grid, IconButton, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { FormInput } from '../../Components/CoreLib/library';
import { UnitsSelect } from '../../Components/CommonInputs';
import { useRangeFormManager } from './useRangeFormManager';
import { Delete } from '@mui/icons-material';
import { SimpleConfirmationPrompt } from '../../Components/Global';
import { MeasurementRangeValidationFields } from './useMeasurementRangeFormManager';

export interface IMeasurementRangeRowProps {
    range: MeasurementRangeDto;
    onChange: (updatedRange: MeasurementRangeDto) => void;
    onDelete: (updatedRange: MeasurementRangeDto) => void;
    errorMessages?: Map<MeasurementRangeValidationFields, string>;
}

export const MeasurementRangeRow: FC<IMeasurementRangeRowProps> = ({ range, onChange, onDelete, errorMessages }) => {
    const {
        handleUnitsChanged,
        selectedGenderCodes,
        handleGenderCodesChange,
        handleLowChanged,
        handleHighChanged,
        handleDefaultDescriptionChange,
        handleLowDescriptionChange,
        handleHighDescriptionChange,
        handleInRangeDescriptionChange,
        isDeleteConfirmationVisible,
        setIsDeleteConfirmationVisible,
        handleDeleteConfirmation,
        handleMinAgeChange,
        handleMaxAgeChange,
    } = useRangeFormManager(range, onChange, onDelete);

    const isDescriptionDisabled = (low: number | null | undefined, high: number | null | undefined, checkBoth: boolean = false) => {
        if (checkBoth) {
            return low == null || high == null;
        }
        return low == null && high == null;
    };
    return (
        <Grid container direction='column'>
            <Grid container direction='row' spacing={1} wrap='nowrap' alignItems='end'>
                <Grid item flexGrow={1} alignItems='end' display='flex' gap={1}>
                    <UnitsSelect value={range.units} onChange={handleUnitsChanged} errorText={errorMessages?.get('units')} fullWidth required />
                    <Box>
                        <ToggleButtonGroup value={selectedGenderCodes} onChange={handleGenderCodesChange} color='primary' size='large'>
                            <ToggleButton style={{ fontSize: '24px', padding: '6px 12px' }} value='M'>
                                M
                            </ToggleButton>
                            <ToggleButton style={{ fontSize: '24px', padding: '6px 16px' }} value='F'>
                                F
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <FormHelperText error>{errorMessages?.get('genderCodes')}</FormHelperText>
                    </Box>
                </Grid>
                <Grid item width='146px'>
                    <FormInput
                        label='Min Patient Age'
                        type='number'
                        value={range.minAge?.toString()}
                        onChange={handleMinAgeChange}
                        errorText={errorMessages?.get('minAge')}
                        fullWidth
                    />
                </Grid>
                <Grid item width='146px'>
                    <FormInput
                        label='Max Patient Age'
                        type='number'
                        value={range.maxAge?.toString()}
                        onChange={handleMaxAgeChange}
                        errorText={errorMessages?.get('maxAge')}
                        fullWidth
                    />
                </Grid>
                <Grid item width='146px'>
                    <FormInput
                        label='Low'
                        type='number'
                        value={range.low?.toString()}
                        onChange={handleLowChanged}
                        errorText={errorMessages?.get('low')}
                        fullWidth
                    />
                </Grid>
                <Grid item width='146px'>
                    <FormInput
                        label='High'
                        type='number'
                        value={range.high?.toString()}
                        onChange={handleHighChanged}
                        errorText={errorMessages?.get('high')}
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <Box alignSelf='center' pt={3.5}>
                        <IconButton onClick={() => setIsDeleteConfirmationVisible(true)}>
                            <Delete />
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>
            <Grid container direction='row' spacing={1} alignItems='start'>
                <Grid item xs={12}>
                    <FormInput
                        label='Default Description'
                        type='text'
                        value={range?.defaultDescription?.toString()}
                        onChange={handleDefaultDescriptionChange}
                        fullWidth
                        multiline
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormInput
                        label='Low Description'
                        disabled={isDescriptionDisabled(range.low, undefined)}
                        type='text'
                        value={range?.lowDescription?.toString()}
                        onChange={handleLowDescriptionChange}
                        fullWidth
                        multiline
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormInput
                        label='High Description'
                        disabled={isDescriptionDisabled(undefined, range.high)}
                        type='text'
                        value={range?.highDescription?.toString()}
                        onChange={handleHighDescriptionChange}
                        fullWidth
                        multiline
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormInput
                        label='In Range Description'
                        type='text'
                        value={range?.inRangeDescription?.toString()}
                        onChange={handleInRangeDescriptionChange}
                        disabled={isDescriptionDisabled(range.low, range.high, true)}
                        fullWidth
                        multiline
                    />
                </Grid>
            </Grid>
            <SimpleConfirmationPrompt
                isVisible={isDeleteConfirmationVisible}
                icon={<Delete />}
                title='Delete Rule'
                message={`Are you sure you would like to delete this Rule`}
                onCancel={() => setIsDeleteConfirmationVisible(false)}
                onConfirm={handleDeleteConfirmation}
            />
        </Grid>
    );
};
