import { useAuth0 } from '@auth0/auth0-react';
import { Preview } from '@mui/icons-material';
import { Box, Button, Grid, Paper, Typography, useTheme } from '@mui/material';
import { DeepChat } from 'deep-chat-react';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { MessageContentDto } from '../../dtos';
import { useLazyGetChatByIdQuery } from '../../store/generated/generatedApi';
import { AuthenticatedComponent, LoadingIndicator } from '../../Components/CoreLib/library';

export const ChatHome: FC = () => {
    const theme = useTheme();
    const { user: auth0User, getAccessTokenSilently } = useAuth0();
    const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
    const [searchParams] = useSearchParams();
    const threadIdRef = useRef(searchParams.get('threadId'));
    const [threadId, setThreadId] = useState<string | null>(null);
    const [hasUserStartedThread, setHasUserStartedThread] = useState(false);
    const handleThreadClick = useCallback(() => window.open(`https://platform.openai.com/playground?mode=assistant&thread=${threadId}`), [threadId]);
    const [getThreadMessages, { isLoading: isLoadingThreadMessages }] = useLazyGetChatByIdQuery();
    const [loadedThreadMessages, setLoadedThreadMessages] = useState<MessageContentDto[]>([]);

    useEffect(() => {
        var searchParams = new URLSearchParams(window.location.search);
        const urlThreadId = searchParams.get('threadId');
        setThreadId(urlThreadId);
        // eslint-disable-next-line
    }, [getThreadMessages, window.location.search]);

    useEffect(() => {
        threadIdRef.current = threadId;
        if (!threadId) {
            setLoadedThreadMessages([]);
            setHasUserStartedThread(false);
        }
    }, [threadId]);

    useEffect(() => {
        if (threadId && !hasUserStartedThread) {
            getThreadMessages({ threadId: threadId })
                .unwrap()
                .then((messages) => setLoadedThreadMessages([...messages].reverse().slice(1)));
        }
    }, [threadId, hasUserStartedThread, getThreadMessages]);

    useEffect(() => {
        if (accessToken) {
            return;
        }
        (async () => {
            const accessToken = await getAccessTokenSilently({
                authorizationParams: {
                    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                    redirect_uri: window.location.origin,
                    scope: 'SCOPE profile email offline_access',
                },
            });
            setAccessToken(accessToken);
        })();
    }, [accessToken, getAccessTokenSilently]);

    const deepChatRequestInterceptor = useCallback(
        (details: any) => {
            setHasUserStartedThread(true);
            details.headers.authorization = `Bearer ${accessToken}`;
            if (threadIdRef.current) {
                console.log('setting Thread ID on message to : ', threadIdRef.current);
                details.body.threadId = threadIdRef.current;
            }

            return details;
        },
        [accessToken]
    );

    const deepChatResponseInterceptor = useCallback((response: any) => {
        window.history.pushState({}, '', `?threadId=${response.threadId}`);
        setThreadId(response.threadId);
        return response;
    }, []);

    const MemorizedDeepChatComponent = useMemo(
        () => (
            <DeepChat
                style={{ border: 'none', borderRadius: '12px', width: '100%', height: '100%' }}
                initialMessages={loadedThreadMessages as any}
                displayLoadingBubble={true}
                avatars={{
                    default: {
                        styles: {
                            avatar: {
                                paddingTop: '0px',
                                marginTop: '5px',
                            },
                        },
                    },
                    user: {
                        src: auth0User?.picture ?? undefined,
                        styles: {
                            avatar: {
                                borderRadius: '50%',
                            },
                        },
                    },
                }}
                messageStyles={{
                    default: {
                        user: {
                            bubble: {
                                backgroundColor: theme.palette.primary.main,
                            },
                        },
                    },
                }}
                request={{
                    url: `${process.env.REACT_APP_BASE_URL}/api/chat`,
                    method: 'POST',
                }}
                requestInterceptor={deepChatRequestInterceptor}
                responseInterceptor={deepChatResponseInterceptor}
            />
        ),
        [auth0User?.picture, deepChatRequestInterceptor, deepChatResponseInterceptor, theme.palette.primary.main, loadedThreadMessages]
    );

    if (isLoadingThreadMessages) {
        return (
            <Paper sx={{ margin: '24px', borderRadius: '12px', paddingBottom: '8px' }}>
                <Grid container direction='column' textAlign='center' height='calc(100vh - 175px)' justifyContent='center'>
                    <Grid item>
                        <LoadingIndicator />
                    </Grid>
                    <Grid item>
                        <Typography>Loading Chat...</Typography>
                    </Grid>
                </Grid>
            </Paper>
        );
    }

    return (
        <Grid container direction='column'>
            <Grid item container direction='column' justifyContent='start' xs={6}>
                <Paper sx={{ margin: '24px', borderRadius: '12px' }}>
                    <Box display='flex' justifyContent='center' height='calc(100vh - 175px)' alignItems='end'>
                        {MemorizedDeepChatComponent}
                    </Box>
                    <AuthenticatedComponent requiredPermissions={['read:permissionToggle']}>
                        {threadId && (
                            <Button onClick={handleThreadClick}>
                                <Preview />
                                {threadId}
                            </Button>
                        )}
                    </AuthenticatedComponent>
                </Paper>
            </Grid>
        </Grid>
    );
};
