import { useCallback, useMemo, useState } from 'react';
import { MeasurementRangeDto } from '../../dtos';

export function useRangeFormManager(
    range: MeasurementRangeDto,
    onChange: (updatedRange: MeasurementRangeDto) => void,
    onDelete: (updatedRange: MeasurementRangeDto) => void
) {
    const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState(false);

    const handleUnitsChanged = useCallback((selectedUnits: string) => onChange({ ...range, units: selectedUnits }), [range, onChange]);

    const handleLowChanged = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.value === '') {
                onChange({ ...range, low: undefined });
            } else {
                onChange({ ...range, low: parseFloat(event.target.value) });
            }
        },
        [range, onChange]
    );

    const handleHighChanged = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.value === '') {
                onChange({ ...range, high: undefined });
            } else {
                onChange({ ...range, high: parseFloat(event.target.value) });
            }
        },
        [range, onChange]
    );

    const handleGenderCodesChange = useCallback(
        (_: React.MouseEvent<HTMLElement>, newGenderCodes: string[]) => {
            const isMale = newGenderCodes.includes('M');
            const isFemale = newGenderCodes.includes('F');
            onChange({ ...range, isMale, isFemale });
        },
        [range, onChange]
    );

    const selectedGenderCodes = useMemo(() => {
        const genderCodes: string[] = [];
        if (range.isMale) {
            genderCodes.push('M');
        }
        if (range.isFemale) {
            genderCodes.push('F');
        }
        return genderCodes;
    }, [range]);

    const handleDeleteConfirmation = useCallback(() => {
        onDelete(range);
        setIsDeleteConfirmationVisible(false);
    }, [onDelete, range]);

    const handleDefaultDescriptionChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onChange({ ...range, defaultDescription: event.target.value });
        },
        [range, onChange]
    );
    const handleLowDescriptionChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onChange({ ...range, lowDescription: event.target.value });
        },
        [range, onChange]
    );
    const handleHighDescriptionChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onChange({ ...range, highDescription: event.target.value });
        },
        [range, onChange]
    );
    const handleInRangeDescriptionChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onChange({ ...range, inRangeDescription: event.target.value });
        },
        [range, onChange]
    );

    const handleMinAgeChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onChange({ ...range, minAge: parseInt(event.target.value) });
        },
        [range, onChange]
    );

    const handleMaxAgeChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onChange({ ...range, maxAge: parseInt(event.target.value) });
        },
        [range, onChange]
    );

    return {
        handleUnitsChanged,
        handleLowChanged,
        handleHighChanged,
        handleGenderCodesChange,
        handleMinAgeChange,
        handleMaxAgeChange,
        selectedGenderCodes,
        isDeleteConfirmationVisible,
        setIsDeleteConfirmationVisible,
        handleDeleteConfirmation,
        handleDefaultDescriptionChange,
        handleLowDescriptionChange,
        handleHighDescriptionChange,
        handleInRangeDescriptionChange,
    };
}
