import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiError, LoadingIndicator, useFailedActionSnackbar, useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../Components/CoreLib/library';
import { useCreateRangeMutation, useGetRangeQuery } from '../../store/generated/generatedApi';
import { EditMeasurementRangesForm } from './EditMeasurementRangesForm';

export const EditMeasurementRangesView: FC = () => {
    const {
        data: Ranges,
        isLoading: isLoadingRanges,
        isError: isErrorLoadingRanges,
        refetch: refetchRanges,
        isFetching: isFetchingRanges,
    } = useGetRangeQuery();
    useFailedActionSnackbar('load', 'ranges', isErrorLoadingRanges);
    const [saveRanges, { isLoading: isSaving, isError: isErrorSaving, isSuccess: isSuccessSaving, reset: resetSave }] = useCreateRangeMutation();
    useFailedUpdateSnackbar('ranges', isErrorSaving, resetSave);
    useSuccessfulUpdateSnackbar('ranges', isSuccessSaving, resetSave);
    const navigate = useNavigate();

    const handleCancel = useCallback(() => {
        navigate('/');
    }, [navigate]);

    if (isLoadingRanges || isFetchingRanges) {
        return <LoadingIndicator />;
    }

    if (isErrorLoadingRanges || !Ranges) {
        return <ApiError onReloadClick={refetchRanges} />;
    }
    return <EditMeasurementRangesForm initValues={Ranges.pageResults} save={saveRanges} cancel={handleCancel} isLoading={isSaving} />;
};
