import { useAuth0 } from '@auth0/auth0-react';
import { AccountBox, AddCircle, CompareArrows, Label } from '@mui/icons-material';
import { SvgIconProps } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { LoadingIndicator } from './CoreLib/library';
import NavigationDrawer from './NavigationDrawer';
import VerticalAlignCenterIcon from '@mui/icons-material/VerticalAlignCenter';
import StarIcon from '@mui/icons-material/Star';

export interface INavigationDrawerProps extends PropsWithChildren {
    routes: NavigationDrawerRouteDTO[];
    adminRoutes?: NavigationDrawerRouteDTO[];
}

export interface NavigationDrawerRouteDTO {
    icon: React.ReactElement<SvgIconProps>;
    name: string;
    route?: string;
    isHidden?: boolean;
    requiredPermissions?: string[];
    permissionLogic?: 'or' | 'and';
    disableHighlight?: boolean;
}

export const NavigationDrawerContainer: FC<PropsWithChildren> = (props) => {
    const { isLoading, error } = useAuth0();

    const publicRoutes: NavigationDrawerRouteDTO[] = [
        {
            icon: <AddCircle />,
            name: 'Start New Chat',
            disableHighlight: true,
            route: '/',
            requiredPermissions: ['feature:chat'],
        },
        {
            icon: <Label />,
            name: 'Measurement Names',
            route: '/measurements',
            requiredPermissions: ['edit:measurements'],
        },
        {
            icon: <CompareArrows />,
            name: 'Measurement Rules',
            route: '/ranges',
            requiredPermissions: ['edit:measurements'],
        },
        {
            icon: <VerticalAlignCenterIcon />,
            name: 'Panel Rules',
            route: '/panels',
            requiredPermissions: ['edit:panels'],
        },
        {
            icon: <StarIcon />,
            name: 'Special Rules',
            route: '/special-rules',
            requiredPermissions: ['edit:special-rules'],
        },
    ];

    const adminRoutes: NavigationDrawerRouteDTO[] = [
        {
            icon: <AccountBox />,
            name: 'Users',
            route: '/users',
            requiredPermissions: ['read:users'],
        },
    ];

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <p>{error.message}</p>;
    }
    return (
        <div>
            <NavigationDrawer routes={publicRoutes} adminRoutes={adminRoutes}>
                {props.children}
            </NavigationDrawer>
        </div>
    );
};
