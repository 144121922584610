import { Box, Button, Dialog, DialogActions, Typography } from '@mui/material';
import { FC, useCallback } from 'react';
import { StandardDialogHeader } from '../CoreLib/library';

export interface ISimpleConfirmationPromptProps {
    isVisible: boolean;
    title: string,
    message: string,
    onCancel: () => void;
    onConfirm: () => void;
    icon?: JSX.Element;
};

export const SimpleConfirmationPrompt: FC<ISimpleConfirmationPromptProps> = ({
    isVisible,
    title,
    message,
    onCancel,
    onConfirm,
    icon
}) => {
    const handleSaveClicked = useCallback(() => {
        onConfirm();
    }, [onConfirm]);

    return (
        <Dialog open={isVisible}>
            <StandardDialogHeader text={title} icon={icon} />
            <Box p={2}>
                <Typography>{message}?</Typography>
            </Box>
            <DialogActions>
                <Button onClick={onCancel} fullWidth variant='outlined'>Cancel</Button>
                <Button onClick={handleSaveClicked} fullWidth variant='contained'>Confirm</Button>
            </DialogActions>
        </Dialog>
    );
};