import { Box, Grid } from '@mui/material';
import { FC } from 'react';
import { IFormProps, SlimFormHeader } from '../../Components/CoreLib/library';
import { MeasurementRangeCategoryDto } from '../../dtos';
import { MeasurementRangeCategorySection } from './MeasurementRangeCategorySection';
import { useMeasurementRangeFormManager } from './useMeasurementRangeFormManager';
import { CompareArrows } from '@mui/icons-material';

export const EditMeasurementRangesForm: FC<IFormProps<MeasurementRangeCategoryDto[]>> = ({ initValues = [], save, cancel, isLoading }) => {
    const { measurementRangeCategories, rangeErrorMessages, updateRange, addNewRange, removeRange, handleSave } = useMeasurementRangeFormManager(
        initValues,
        save
    );

    return (
        <Grid component='form' container direction='column' spacing={1}>
            <Grid item container sx={{ position: 'sticky', top: 64, zIndex: 3 }}>
                <SlimFormHeader
                    icon={<CompareArrows />}
                    title={'Measurement Rules'}
                    breadcrumbs={[{ label: 'Home', navLink: '/' }]}
                    permissionsTypeName='measurements'
                    canInactivate={false}
                    handleCancel={cancel}
                    handleSave={handleSave}
                    isLoading={isLoading}
                />
            </Grid>
            <Grid item mx={2} display='flex' flexDirection='column' gap={2}>
                <Box display='flex' flexDirection='column' gap={2}>
                    {measurementRangeCategories.map((category, idx) => (
                        <MeasurementRangeCategorySection
                            key={`${category.id}-${category.name}`}
                            category={category}
                            onRangeUpdated={updateRange(idx)}
                            onAddRangeClicked={addNewRange(idx)}
                            onRemoveRangeClicked={removeRange(idx)}
                            rangeErrorMessages={rangeErrorMessages.get(idx)}
                        />
                    ))}
                </Box>
            </Grid>
        </Grid>
    );
};
