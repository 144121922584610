import { Autocomplete, FilterOptionsState, FormControl, FormHelperText, FormLabel, TextField } from '@mui/material';
import { FC, useMemo } from 'react';
import { useGetMeasurementQuery } from '../../store/generated/generatedApi';
import { MeasurementDto } from '../../dtos';
import { emptyGuid } from '../../util';

export interface IMeasurementSelectProps {
    initialMeasurementId: string;
    onMeasurementChange: (measurementId: string) => void;
    errorText?: string;
    label?: string;
    required?: boolean;
    fullWidth?: boolean;
    filterOutIds?: string[];
}

export const MeasurementSingleSelect: FC<IMeasurementSelectProps> = ({
    initialMeasurementId,
    onMeasurementChange,
    errorText = '',
    label = 'Measurement',
    required,
    fullWidth,
    filterOutIds = [],
}) => {
    const { data: knownMeasurements, isLoading: isLoadingMeasurement } = useGetMeasurementQuery();

    const knownMeasurementsArray = useMemo(() => knownMeasurements?.pageResults.flatMap((m) => m.measurements) ?? [], [knownMeasurements]);

    const selectedMeasurement = useMemo(
        () => knownMeasurementsArray.find((measurement) => measurement.id === initialMeasurementId) ?? null,
        [knownMeasurementsArray, initialMeasurementId]
    );

    const combinedFilterOptions = useMemo(
        () => (options: MeasurementDto[], state: FilterOptionsState<MeasurementDto>) => {
            return options
                .filter((option) => !filterOutIds.includes(option.id))
                .filter((option) => option.friendlyName.toLowerCase().includes(state.inputValue.toLowerCase()));
        },
        [filterOutIds]
    );

    return (
        <FormControl required={required} fullWidth={fullWidth} error={!!errorText}>
            <FormLabel>{label}</FormLabel>
            <Autocomplete
                loading={isLoadingMeasurement}
                value={selectedMeasurement}
                selectOnFocus
                onChange={(event, value) => onMeasurementChange(value?.id || emptyGuid)}
                options={knownMeasurementsArray}
                getOptionLabel={(option) => option.friendlyName}
                renderInput={(params) => <TextField {...params} error={!!errorText} />}
                filterOptions={combinedFilterOptions}
            />
            <FormHelperText>{errorText}</FormHelperText>
        </FormControl>
    );
};
