import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserDto } from '../../dtos';
import { ApiError, LoadingIndicator, useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../Components/CoreLib/library';
import { useGetUserByIdQuery, useUpdateUserMutation } from '../../store/generated/generatedApi';
import { UserForm } from './UserForm';

export const EditUserView: FC = (props) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { data, isLoading, error, refetch } = useGetUserByIdQuery({ id: id! });
    const [updateUser, { isSuccess, isError, reset }] = useUpdateUserMutation();

    useSuccessfulUpdateSnackbar('User', isSuccess, () => navigate(0));
    useFailedUpdateSnackbar('user', isError, reset);

    const handleSave = (values: UserDto) => {
        updateUser(values);
    };

    const handleCancel = () => {
        navigate('/users');
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return <UserForm save={handleSave} cancel={handleCancel} initValues={data} />;
};
