import { Menu, Settings } from '@mui/icons-material';
import { Button, Divider, Grid, IconButton, styled, Typography } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { CSSObject, Theme } from '@mui/material/styles';
import { FC, useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import Cookies from 'universal-cookie';
import { INavigationDrawerProps, NavigationDrawerRouteDTO } from './NavigationDrawerContainer';
import { AuthenticatedComponent, UserPermissionContext, userHasPermissions } from './CoreLib/library';

const openedWidth = '260px';
const closedWidth = '65px';

const content_slide_opened = {
    transition: 'width .2s, height .2s',
    transitionTimingFunction: 'ease-out',
};

const content_slide_closed = {
    width: closedWidth,
    transition: 'width .2s,height .2s',
    transitionTimingFunction: 'ease-in',
};

const content_fade_opened = {
    opacity: 1,
    transition: 'opacity .1s',
};

const content_fade_closed = {
    opacity: 0,
    transition: 'opacity .1s',
};

const openedMixin = (theme: Theme): CSSObject => ({
    width: openedWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: closedWidth,
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: openedWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

const NavigationDrawer: FC<INavigationDrawerProps> = (props) => {
    const { routes, adminRoutes } = props;
    const cookies = new Cookies(document.cookie);
    const [isOpen, setIsOpen] = useState(cookies.get('drawerIsOpen') === 'false' ? false : true);
    const [isToggledOpen, setIsToggledOpen] = useState(false);
    const [isAnimationComplete, setIsAnimationComplete] = useState(true);
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { permissions } = useContext(UserPermissionContext);

    const isPartiallyOpen = isOpen || !isAnimationComplete;

    const manualToggleDrawer = () => {
        setIsToggledOpen(!isOpen);
        setDrawerOpen(!isOpen);
    };

    const onDrawerHover = () => {
        if (!isToggledOpen) {
            setDrawerOpen(true);
        }
    };

    const onDrawerLeave = () => {
        if (!isToggledOpen) {
            setDrawerOpen(false);
        }
    };

    const setDrawerOpen = (open: boolean) => {
        setIsOpen(open);
        cookies.set('drawerIsOpen', open);
    };

    const renderNavigationButtons = (routesToRender: NavigationDrawerRouteDTO[], animationStyling?: React.CSSProperties | undefined) => {
        const visibleRoutes = routesToRender.filter((r) => !r.isHidden);
        return visibleRoutes.map((routeToRender) => {
            return createNavButton(routeToRender, animationStyling);
        });
    };

    const getPermittedAdminRoutes = () => {
        if (!adminRoutes) {
            return [];
        }

        return adminRoutes.filter((adminRoute) => userHasPermissions(adminRoute.requiredPermissions ?? [], permissions));
    };

    const createNavButton = (route: NavigationDrawerRouteDTO, animationStyling: React.CSSProperties | undefined) => {
        const NavButton = isPartiallyOpen ? (
            <Button
                key={route.route}
                className='nav-button full-width'
                onClick={(e) => {
                    e.preventDefault();
                    navigate(route.route!);
                }}
                href={route.route!}
                startIcon={route.icon}
                sx={
                    pathname === route.route && !route.disableHighlight
                        ? { backgroundColor: 'rgba(255,255,255,.2)', fontWeight: 'bold' }
                        : { fontWeight: 'normal' }
                }
                style={animationStyling}>
                {<div style={isOpen ? content_fade_opened : content_fade_closed}>{route.name}</div>}
            </Button>
        ) : (
            <IconButton
                key={route.route}
                className='nav-button'
                onClick={(e) => {
                    e.preventDefault();
                    navigate(route.route!);
                }}
                href={route.route!}
                sx={pathname === route.route && !route.disableHighlight ? { backgroundColor: 'rgba(255,255,255,.2)', left: '-4px' } : { left: '-4px' }}>
                {route.icon}
            </IconButton>
        );

        return (
            <AuthenticatedComponent key={route.route} requiredPermissions={route.requiredPermissions ?? []}>
                {NavButton}
            </AuthenticatedComponent>
        );
    };

    return (
        <>
            <Drawer
                variant='permanent'
                anchor='left'
                open={isOpen}
                onMouseEnter={() => {
                    onDrawerHover();
                }}
                onMouseLeave={() => {
                    onDrawerLeave();
                }}
                onTransitionEnd={() => setIsAnimationComplete(!isOpen)}>
                <Grid container alignItems='start' sx={{ marginBottom: '20px' }}>
                    <IconButton edge='start' onClick={manualToggleDrawer}>
                        <Menu />
                    </IconButton>
                </Grid>
                <Grid
                    container
                    direction='column'
                    alignItems='start'
                    gap='8px'
                    onMouseEnter={() => {
                        onDrawerHover();
                    }}>
                    {renderNavigationButtons(routes, isOpen ? content_slide_opened : content_slide_closed)}
                    {!isPartiallyOpen && getPermittedAdminRoutes().length > 0 && (
                        <IconButton className='nav-button' sx={{ fontWeight: 'bold', left: '-4px' }} style={isOpen ? content_fade_closed : content_fade_opened}>
                            <Settings />
                        </IconButton>
                    )}
                </Grid>
                {isPartiallyOpen && getPermittedAdminRoutes().length > 0 && (
                    <div style={isOpen ? content_fade_opened : content_fade_closed}>
                        <Divider sx={{ background: 'white', margin: '20px -20px 20px' }} />
                        <Grid container>
                            <Typography sx={{ marginBottom: '10px' }}>Admin</Typography>
                            {renderNavigationButtons(getPermittedAdminRoutes())}
                        </Grid>
                    </div>
                )}
            </Drawer>
            <Grid
                sx={
                    isOpen
                        ? { marginLeft: openedWidth, transition: 'margin .2s', transitionTimingFunction: 'ease-out' }
                        : { marginLeft: closedWidth, transition: 'margin .2s', transitionTimingFunction: 'ease-in' }
                }>
                {props.children}
            </Grid>
        </>
    );
};

export default NavigationDrawer;
