import { Error } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import {FC} from 'react';

export interface IErrorTooltipProps {
    message?: string;
};

export const ErrorTooltip: FC<IErrorTooltipProps> = ({ message }) => {
    
    if (!message) {
        return null;
    }

    return (
        <Tooltip title={message}>
            <Error color='error' />
        </Tooltip>
    )
};