import { useCallback } from "react";

export function useHelperMethods() {
    const stopPropagationAndThen = useCallback((callback: () => void) => (event: React.MouseEvent) => {
        event.stopPropagation();
        callback();
    }, []);

    return {
        stopPropagationAndThen
    };
}