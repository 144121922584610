import { useCallback } from 'react';
import { MeasurementDto } from '../../dtos';

export function useMeasurementFormManager(measurement: MeasurementDto, onChange: (updatedMeasurement: MeasurementDto) => void) {
    const handleMeasurementChanged = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onChange({ ...measurement, friendlyName: event.target.value });
        },
        [onChange, measurement]
    );

    const handleDescriptionChanged = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onChange({ ...measurement, description: event.target.value });
        },
        [onChange, measurement]
    );

    const handleAliasesChanged = useCallback(
        (newAliases: string[]) => {
            onChange({ ...measurement, aliases: newAliases });
        },
        [onChange, measurement]
    );

    return {
        handleAliasesChanged,
        handleMeasurementChanged,
        handleDescriptionChanged,
    };
}
