import { Box, Button, Grid, Paper } from '@mui/material';
import { FC } from 'react';
import { IFormProps, SlimFormHeader } from '../../Components/CoreLib/library';
import { PanelDto } from '../../dtos';
import VerticalAlignCenterIcon from '@mui/icons-material/VerticalAlignCenter';
import { usePanelFormManager } from './usePanelFormManager';
import { PanelSection } from './PanelSection';
import { AddCircle } from '@mui/icons-material';

export const EditPanelsForm: FC<IFormProps<PanelDto[]>> = ({ initValues = [], save, cancel, isLoading }) => {
    const { panels, handleSave, addNewPanel, updatePanel, handleDeleteRow } = usePanelFormManager(initValues, save);

    return (
        <Grid component='form' container direction='column' spacing={1}>
            <Grid item container sx={{ position: 'sticky', top: 64, zIndex: 3 }}>
                <SlimFormHeader
                    icon={<VerticalAlignCenterIcon />}
                    title={'Panel Rules'}
                    breadcrumbs={[{ label: 'Home', navLink: '/' }]}
                    permissionsTypeName='panels'
                    canInactivate={false}
                    handleCancel={cancel}
                    handleSave={handleSave}
                    isLoading={isLoading}
                />
            </Grid>
            <Grid item mx={2} display='flex' flexDirection='column' gap={2}>
                <Paper sx={{ p: 1 }}>
                    <Box display='flex' flexDirection='column' gap={1} width='100%'>
                        {panels.map((panel, idx) => (
                            <PanelSection key={`${panel.id}-${idx}`} panel={panel} idx={idx} updatePanel={updatePanel} handleDeleteRow={handleDeleteRow} />
                        ))}
                        <Button fullWidth color='primary' variant='contained' startIcon={<AddCircle />} onClick={addNewPanel}>
                            New Rule
                        </Button>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );
};
