import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: { main: '#2A67A5', contrastText: '#ffffff' },
        secondary: { main: '#103058', contrastText: '#ffffff' },
        error: { main: '#f44336', contrastText: '#ffffff' },
        info: { main: '#cccccc', contrastText: '#ffffff' },
        background: {
            default: '#fafafa',
        },
        text: {
            primary: '#58585A',
        },
    },
    components: {
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '& .MuiTableCell-root .hover-actions': {
                        display: 'none',
                    },
                    '&:hover .MuiTableCell-root .hover-actions': {
                        display: 'block',
                    },
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    backgroundColor: '#2A67A5',
                    color: '#ffffff',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                h1: {
                    display: 'inline-flex',
                    verticalAlign: 'middle',
                    fontSize: '32px',
                    fontWeight: 'bold',
                    '& .MuiSvgIcon-root': {
                        fontSize: '32px',
                        marginRight: '12px',
                    },
                },
                h2: {
                    display: 'inline-flex',
                    verticalAlign: 'middle',
                    fontSize: '24px',
                    fontWeight: 'bold',
                    '& .MuiSvgIcon-root': {
                        fontSize: '32px',
                        marginRight: '12px',
                    },
                },
                subtitle1: {
                    color: '#58585A',
                    fontSize: '20px',
                    fontWeight: 'normal',
                },
                h3: {
                    display: 'inline-flex',
                    verticalAlign: 'middle',
                    fontSize: '24px',
                    fontWeight: 'normal',
                    '& .MuiSvgIcon-root': {
                        fontSize: '24px',
                        marginRight: '8px',
                    },
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                root: {
                    '& .MuiDrawer-paper': {
                        background: 'linear-gradient(221deg, #2A67A5, #7Ec3b1, #2A67A5)',
                        color: '#ffffff',
                        padding: '0px 20px',
                        borderRight: 'none',
                    },
                    '& .MuiDrawer-paper .MuiButtonBase-root': {
                        color: '#ffffff',
                        fontSize: '16px',
                        textTransform: 'none',
                    },
                    '& .MuiDrawer-paper .MuiButtonBase-root.nav-button': {
                        padding: '8px',
                        marginLeft: '-8px',
                        justifyContent: 'start',
                    },
                    '& .MuiDrawer-paper .MuiButtonBase-root.nav-button.full-width': {
                        padding: '8px 12px',
                        marginLeft: '-12px',
                        width: 'calc(100% + 12px)',
                    },
                    '& .MuiDrawer-paper .MuiButtonBase-root.nav-button:hover': {
                        backgroundColor: '#A0CCCA',
                    },
                    '& .MuiDrawer-paper .MuiButtonBase-root .MuiSvgIcon-root': {
                        fontSize: '32px',
                    },
                    '& .MuiDrawer-paper .MuiButtonBase-root .MuiButton-iconSizeMedium': {
                        marginRight: '24px',
                    },
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: '8px 24px',
                    '& .MuiButton-root': {
                        minWidth: '100px',
                    },
                    '& > :not(:first-of-type)': {
                        marginLeft: '24px',
                    },
                },
            },
        },
    },
});

export default theme;
