import {FC, useContext, useMemo} from 'react';
import { UserPermissionContext, userHasPermissions } from '../../Components/CoreLib/library';
import { ChatHome } from './ChatHome';
import { NonChatHome } from './NonChatHome';


export const Home: FC = () => {
    const { permissions } = useContext(UserPermissionContext);
    const isChatEnabled = useMemo(() => userHasPermissions(['feature:chat'], permissions), [permissions]);

    if (isChatEnabled) {
        return <ChatHome />;
    }

    return <NonChatHome />;
};