import { AddCircle, Delete, Edit, ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, IconButton, Paper } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { ErrorTooltip, SimpleTextInputPrompt } from '../../Components/Global';
import { MeasurementCategoryDto, MeasurementDto } from '../../dtos';
import { MeasurementCategoryMeasurementRow } from './MeasurementCategoryMeasurementRow';
import { emptyGuid, useHelperMethods } from '../../util';

const DEFAULT_MEASUREMENT: MeasurementDto = {
    id: emptyGuid,
    friendlyName: '',
    description: '',
    aliases: [],
    categoryId: emptyGuid,
    categoryName: '',
};

export interface IMeasurementCategorySectionProps {
    value: MeasurementCategoryDto;
    onChange: (updatedMeasurement: MeasurementCategoryDto) => void;
    onDeleteClicked: () => void;
    categoryNameValidator: (currentValue: string) => string;
    categoryErrorMessages?: Map<keyof MeasurementCategoryDto, string>;
    measurementErrorMessages?: Map<number, Map<keyof MeasurementDto, string>>;
    allUsedAliases: string[];
}

export const MeasurementCategorySection: FC<IMeasurementCategorySectionProps> = ({
    value,
    onChange,
    onDeleteClicked,
    measurementErrorMessages,
    categoryErrorMessages,
    categoryNameValidator,
    allUsedAliases,
}) => {
    const { stopPropagationAndThen } = useHelperMethods();
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    const handleEditCancel = useCallback(() => setIsEditModalOpen(false), []);
    const handleEditConfirm = useCallback(
        (newName: string) => {
            onChange({ ...value, name: newName });
        },
        [onChange, value]
    );

    const handleEditClicked = stopPropagationAndThen(() => setIsEditModalOpen(true));
    const handleDeleteClicked = stopPropagationAndThen(onDeleteClicked);

    const handleMeasurementChange = useCallback(
        (index: number) => (updatedMeasurement: MeasurementDto) => {
            const updatedMeasurements = [...value.measurements];
            updatedMeasurements[index] = updatedMeasurement;
            onChange({ ...value, measurements: updatedMeasurements });
        },
        [onChange, value]
    );
    const handleMeasurementDelete = useCallback(
        (index: number) => () => {
            const updatedMeasurements = [...value.measurements];
            updatedMeasurements.splice(index, 1);
            onChange({ ...value, measurements: updatedMeasurements });
        },
        [onChange, value]
    );
    const handleAddMeasurement = useCallback(() => {
        const newMeasurement = { ...DEFAULT_MEASUREMENT, categoryId: value.id, categoryName: value.name };
        onChange({ ...value, measurements: [...value.measurements, newMeasurement] });
    }, [onChange, value]);

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Box display='flex' alignItems='center' gap={1}>
                    {value.name}
                    <ErrorTooltip message={categoryErrorMessages?.get('name')} />
                    <IconButton size='small' onClick={handleEditClicked}>
                        <Edit />
                    </IconButton>
                    <IconButton size='small' onClick={handleDeleteClicked}>
                        <Delete />
                    </IconButton>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Box display='flex' flexDirection='column' gap={2}>
                    {value.measurements.map((measurement, index) => (
                        <Paper key={`${measurement.id}-${index}`} sx={{ p: 1 }}>
                            <MeasurementCategoryMeasurementRow
                                measurement={measurement}
                                onChange={handleMeasurementChange(index)}
                                onDelete={handleMeasurementDelete(index)}
                                errorMessages={measurementErrorMessages?.get(index)}
                                allUsedAliases={allUsedAliases}
                            />
                        </Paper>
                    ))}
                    <Button fullWidth color='primary' variant='contained' startIcon={<AddCircle />} onClick={handleAddMeasurement}>
                        New Measurement
                    </Button>
                </Box>
            </AccordionDetails>
            <SimpleTextInputPrompt
                isVisible={isEditModalOpen}
                icon={<Edit />}
                title='Edit Category'
                fieldLabel='Name'
                initialValue={value.name}
                onCancel={handleEditCancel}
                onConfirm={handleEditConfirm}
                customValidationMethod={categoryNameValidator}
            />
        </Accordion>
    );
};
