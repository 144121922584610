import { PanelDto, PanelMeasurementDto } from '../../dtos';

export function usePanelRowFormManager(value: PanelDto, idx: number, updatePanel: (updatedPanel: PanelDto, idx: number) => void) {
    const handleNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedPanel = { ...value, name: event.target.value };
        updatePanel(updatedPanel, idx);
    };

    const handleDescriptionChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedPanel = { ...value, defaultDescription: event.target.value };
        updatePanel(updatedPanel, idx);
    };

    const handleAutoCompleteChanged = (panelMeasurements: PanelMeasurementDto[]) => {
        const updatedPanel = { ...value, measurements: panelMeasurements };
        updatePanel(updatedPanel, idx);
    };

    return {
        handleNameChanged,
        handleDescriptionChanged,
        handleAutoCompleteChanged,
    };
}
