import { Box, Grid, Paper, Typography } from '@mui/material';
import { FC } from 'react';
import { IFormProps, SlimFormHeader } from '../../Components/CoreLib/library';
import StarIcon from '@mui/icons-material/Star';
import { SpecialRuleDto } from '../../dtos/generated/SpecialRuleDto';
import { useSpecialRuleFormManager } from './useSpecialRuleFormManager';
import { SpecialRuleSection } from './SpecialRuleSection';
import { emptyGuid } from '../../util';

const measurements: { key: keyof SpecialRuleDto; label: string }[] = [
    { key: 'albuminMeasurementId', label: 'Albumin' },
    { key: 'creatinineMeasurementId', label: 'Creatinine' },
    { key: 'glucoseMeasurementId', label: 'Glucose' },
    { key: 'crpMeasurementId', label: 'C-reactive Protein (CRP)' },
    { key: 'lymphocyteMeasurementId', label: 'Lymphocyte (Lymphs)' },
    { key: 'meanCellVolumeMeasurementId', label: 'Mean Cell Volume (MCV)' },
    { key: 'redCellDistWidthMeasurementId', label: 'Red Cell Dist Width (RDW)' },
    { key: 'alkalinePhosphataseMeasurementId', label: 'Alkaline Phosphatase' },
    { key: 'whiteBloodCellsMeasurementId', label: 'White Blood Cells' },
];

const DEFAULT_VALUES: SpecialRuleDto = {
    id: emptyGuid,
    creatinineMeasurementId: emptyGuid,
    albuminMeasurementId: emptyGuid,
    glucoseMeasurementId: emptyGuid,
    crpMeasurementId: emptyGuid,
    lymphocyteMeasurementId: emptyGuid,
    meanCellVolumeMeasurementId: emptyGuid,
    redCellDistWidthMeasurementId: emptyGuid,
    alkalinePhosphataseMeasurementId: emptyGuid,
    whiteBloodCellsMeasurementId: emptyGuid,
};

export const EditSpecialRulesForm: FC<IFormProps<SpecialRuleDto>> = ({ initValues = DEFAULT_VALUES, cancel, save, isLoading }) => {
    const { specialRules, handleSave, updateSpecialRule, filterOutIds } = useSpecialRuleFormManager(initValues, save);

    return (
        <Grid component='form' container direction='column' spacing={1}>
            <Grid item container sx={{ position: 'sticky', top: 64, zIndex: 3 }}>
                <SlimFormHeader
                    icon={<StarIcon />}
                    title={'Special Rules'}
                    breadcrumbs={[{ label: 'Home', navLink: '/' }]}
                    permissionsTypeName='special-rules'
                    canInactivate={false}
                    handleCancel={cancel}
                    handleSave={handleSave}
                    isLoading={isLoading}
                />
            </Grid>
            <Grid item mx={2} display='flex' flexDirection='column' gap={2}>
                <Paper sx={{ p: 1 }}>
                    <Typography p={1}>Phenotypic Age Mappings</Typography>
                    <Box display='flex' flexDirection='column' gap={2} sx={{ px: 1 }} width='100%'>
                        {measurements.map((measurement) => (
                            <SpecialRuleSection
                                key={measurement.key}
                                measurement={measurement.label}
                                initialMeasurementId={specialRules[measurement.key]}
                                onMeasurementChange={(value) => updateSpecialRule(measurement.key, value)}
                                filterOutIds={filterOutIds}
                            />
                        ))}
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );
};
